import React from "react";
import NavPills from "components/NavPills/NavPills.js";

const CustomNavPills = ({
  color,
  tabButton1,
  tabIcon1,
  tabContent1,
  tabButton2,
  tabIcon2,
  tabContent2,
}) => {
  return (
    <NavPills
      customize={true}
      color={color}
      horizontal={{
        tabsGrid: { xs: 12, sm: 3, md: 2 },
        contentGrid: { xs: 12, sm: 9, md: 10 },
      }}
      tabs={[
        {
          tabButton: tabButton1,
          tabIcon: tabIcon1,
          tabContent: tabContent1,
        },
        {
          tabButton: tabButton2,
          tabIcon: tabIcon2,
          tabContent: tabContent2,
        },
      ]}
    />
  );
};

export default CustomNavPills;
