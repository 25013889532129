import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import ModalComponent from "components/ModalComponent";
import CustomInput from "components/CustomInput/CustomInput.js";
import ApiService from "api/ApiService.js";
import ReactTable from "react-table";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import InfoIcon from "@material-ui/icons/Info";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { useTranslation } from "react-i18next";
import AssignmentIcon from "@material-ui/icons/Assignment";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import ClipLoader from "react-spinners/ClipLoader";
import { grayColor } from "assets/jss/material-dashboard-pro-react";
import { dangerColor } from "assets/jss/material-dashboard-pro-react";

const Assessment = (props) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [sharedLoading, setSharedLoading] = useState(true);
  const [openNewAssessmentModal, setOpenNewAssessmentModal] = useState(false);
  const [containerTitle, setContainerTitle] = useState("");
  const [year, setYear] = useState("2022");
  const [nace, setNace] = useState("");
  const [industry, setIndustry] = useState("");
  const [errors, setErrors] = useState("");
  const [documentContainers, setDocumentContainers] = useState([]);
  const [deleteResponse, setDeleteResponse] = useState({});
  const [sharedDocumentContainers, setSharedDocumentContainers] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const apiOperations = () => {
      const getQueryParams = async (signal = undefined) => {
        const queryParams = new URLSearchParams(props.location.search);
        const params = {
          userId: queryParams.get("userId"),
        };
        const returnData = await ApiService.loginRequired(signal, false);
        if (returnData.superuser && params.userId) {
          const userProfileData = await ApiService.readUser(params, signal);
          params.userId = userProfileData._id;
        } else {
          params.userId = returnData._id;
        }
        params.superuser = returnData.superuser;
        return params;
      };

      const getDocumentContainers = async (signal = undefined) => {
        setLoading(true);
        try {
          const queryParams = await getQueryParams(signal);
          const params = {
            userId: queryParams.userId,
            sortBy: "createdAt:desc",
            populateSignatures: true,
            filters: JSON.stringify({ "flags.name": "esg" }),
          };
          const responseData = await ApiService.getDocumentContainers(
            params,
            signal
          );
          const parsedResponse = responseData.map((data) => {
            data.message = JSON.parse(data.message);
            return data;
          });
          setDocumentContainers(parsedResponse);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
        await getQueryParams(signal);
      };

      const getSharedDocumentContainers = async () => {
        setSharedLoading(true);
        try {
          const queryParams = await getQueryParams();
          const params = {
            userId: queryParams.userId,
            parentType: ApiService.parentTypes.container,
            populateParent: "true",
            sortBy: "createdAt:desc",
            populateSignatures: true,
            filters: JSON.stringify({ "flags.name": "esg" }),
          };
          const responseData = await ApiService.getAccessRights(params);
          const parsedResponse = responseData.map((data) => {
            data.message = JSON.parse(data.message);
            return data;
          });
          setSharedDocumentContainers(
            parsedResponse.filter((container) => container.status !== "DELETED")
          );
          setSharedLoading(false);
        } catch (e) {
          console.error(e);
          setSharedLoading(false);
        }
      };
      getDocumentContainers();
      getSharedDocumentContainers();
    };

    isMounted && apiOperations();
    return () => {
      isMounted = false;
    };
  }, [deleteResponse, props.location.search]);

  // create new assessment modal
  const handleCloseModal = () => {
    setOpenNewAssessmentModal(false);
  };

  const handleOpenModal = () => {
    setOpenNewAssessmentModal(true);
  };

  // create assessment
  const handleCreateAssessment = async () => {
    const validTitle = containerTitle.length > 0;
    const validYear = year.length > 0;
    const validIndustry = industry.length > 0;
    const ttl = "-1";

    const checksPassed = validTitle && validYear && validIndustry;
    if (!checksPassed) {
      if (!validTitle) {
        setErrors(t("invalid-name"));
        return;
      }
      if (!validYear) {
        setErrors(t("invalid-year"));
        return;
      }
      if (!validIndustry) {
        setErrors(t("invalid-industry"));
        return;
      }
    }

    const userProfileData = await ApiService.readUser();

    const params = {
      title: containerTitle,
      message: JSON.stringify({
        type: "header",
        name: containerTitle,
        year: year,
        naceCode: nace,
        industry,
        accountant: userProfileData.email,
      }),
      access: "PRIVATE",
      endOfLife: ttl,
      flags: [
        { name: "esg", value: true },
        { name: year, value: true },
      ],
    };

    let docContainer;
    try {
      docContainer = await ApiService.addDocumentContainer(params);
    } catch (e) {
      console.error(e);
    }
    setOpenNewAssessmentModal(false);
    props.history.push(`/admin/assessments/details/${docContainer._id}`);
  };

  // delete assesment by id
  const handleDeleteContainer = async (id) => {
    try {
      const response = await ApiService.deleteDocumentContainer({
        documentContainerId: id,
      });
      setDeleteResponse(response);
    } catch (e) {
      console.error(e);
    }
  };

  // prepare data for ReactTable
  const prepareData = (containers, isShared = false) => {
    return containers.map((data) => {
      return {
        title: data.title,
        year: data.message.year,
        nace: data.message?.naceCode,
        industry: data.message.industry,
        accountant: data.message.accountant,
        status:
          data.signatures.length > 0
            ? ApiService.jobStatus.completed
            : ApiService.jobStatus.inProgress,
        actions: (
          <>
            <Button
              onClick={() =>
                props.history.push(`/admin/assessments/details/${data._id}`)
              }
              color="rose"
            >
              {t("view")}
            </Button>
            {!isShared && (
              <Tooltip title={t("delete-assessment")}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteContainer(data._id)}
                  style={{ fontSize: "1em" }}
                >
                  <ClearIcon
                    style={{
                      color: grayColor[20],
                      fontSize: "1em",
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      };
    });
  };

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#dbb686"} loading={loading} />
      </div>
    );
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={11} sm={4} md={2}></GridItem>
        <GridItem xs={1} sm={2} md={6}></GridItem>
        <GridItem xs={12} sm={6} md={4} className={classes2.chip}>
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            {t("add-new")}
          </Button>
        </GridItem>
      </GridContainer>

      {loading && sharedLoading && <Loading />}

      {!loading &&
        !sharedLoading &&
        documentContainers.length === 0 &&
        sharedDocumentContainers.length === 0 && (
          <>
            <Card pricing plain>
              <CardBody pricing>
                <div className={classes.icon}>
                  <AssignmentIcon className={classes.iconRose} />
                </div>
                <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  {t("no-assessments-created")}
                </h5>
                <p className={classes.cardDescription}>
                  {t("start-creating-assessments-by-clicking-add-new")}
                </p>
              </CardBody>
            </Card>
          </>
        )}

      {!loading && documentContainers.length > 0 && (
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <InfoIcon />
              </CardIcon>
              <h4 className={classes2.cardIconTitle}>{t("my-assessments")}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={prepareData(documentContainers)}
                sortable={true}
                filterable={true}
                columns={[
                  {
                    Header: t("title"),
                    accessor: "title",
                  },
                  {
                    Header: t("accountant"),
                    accessor: "accountant",
                  },
                  {
                    Header: t("year"),
                    accessor: "year",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("industry"),
                    accessor: "industry",
                  },

                  {
                    Header: t("status"),
                    accessor: "status",
                  },
                  {
                    Header: t("nace-code"),
                    accessor: "nace",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                ]}
                showPaginationTop={false}
                showPaginationBottom={true}
                defaultPageSize={10}
                nextText={t("next")}
                previousText={t("previous")}
                loadingText={t("loading")}
                noDataText={t("no-data")}
                pageText={t("page")}
                ofText={t("of")}
                rowsText={t("rows")}
              />
            </CardBody>
          </Card>
        </GridItem>
      )}

      {!sharedLoading && sharedDocumentContainers.length > 0 && (
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <InfoIcon />
              </CardIcon>
              <h4 className={classes2.cardIconTitle}>
                {t("shared-assessments")}
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={prepareData(sharedDocumentContainers, true)}
                sortable={true}
                filterable={true}
                columns={[
                  {
                    Header: t("title"),
                    accessor: "title",
                  },
                  {
                    Header: t("accountant"),
                    accessor: "accountant",
                  },
                  {
                    Header: t("year"),
                    accessor: "year",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("industry"),
                    accessor: "industry",
                  },

                  {
                    Header: t("status"),
                    accessor: "status",
                  },
                  {
                    Header: t("nace-code"),
                    accessor: "nace",
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    headerStyle: { textAlign: "right" },
                    Cell: (row) => (
                      <div style={{ textAlign: "right" }}>{row.value}</div>
                    ),
                  },
                ]}
                showPaginationTop={false}
                showPaginationBottom={true}
                defaultPageSize={10}
                nextText={t("next")}
                previousText={t("previous")}
                loadingText={t("loading")}
                noDataText={t("no-data")}
                pageText={t("page")}
                ofText={t("of")}
                rowsText={t("rows")}
              />
            </CardBody>
          </Card>
        </GridItem>
      )}

      <ModalComponent
        open={openNewAssessmentModal}
        handleClose={handleCloseModal}
        dialogTitle={t("create-new-assessment")}
        maxWidth="xs"
        dialogContent={
          <GridContainer alignItems="baseline">
            <GridItem xs={12} sm={12}>
              <CustomInput
                labelText={t("name")}
                id="help-text"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: containerTitle || "",
                  onChange: (e) => {
                    setContainerTitle(e.target.value);
                  },
                  placeholder: t("name"),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={t("year")}
                id="help-text"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "number",
                  step: "1",
                  value: year || "",
                  onChange: (e) => {
                    setYear(e.target.value);
                  },
                  placeholder: t("year"),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={t("nace-code-optional")}
                id="help-text"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: nace || "",
                  onChange: (e) => {
                    setNace(e.target.value);
                  },
                  placeholder: t("nace-code-optional"),
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                labelText={t("industry")}
                id="help-text"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  value: industry || "",
                  onChange: (e) => {
                    setIndustry(e.target.value);
                  },
                  placeholder: t("industry"),
                }}
              />
            </GridItem>
            {errors.length > 0 && (
              <h6 style={{ color: dangerColor[0] }}>{errors}</h6>
            )}
          </GridContainer>
        }
        dialogActions={
          <React.Fragment>
            <Button simple onClick={handleCloseModal} color="rose">
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateAssessment}
            >
              {t("create")}
            </Button>
          </React.Fragment>
        }
      />
    </>
  );
};

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles(() => ({
  cardTitle,
  cardIconTitle: {
    marginTop: "35px",
  },
  chip: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    "@media (max-width: 599px)": {
      justifyContent: "flex-start",
    },
  },
}));

export default Assessment;
