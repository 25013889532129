import React from "react";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snack from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.js";
import { roseColor } from "assets/jss/material-dashboard-pro-react";
import ApiService from "api/ApiService";

const useStyles = makeStyles(styles);

export default function SnackbarContent(props) {
  const classes = useStyles();
  const { message, color, close, icon, click, clickAction, button } = props;
  var action = [];
  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined,
  });
  if (clickAction !== undefined) {
    if (clickAction === "invalid user" && ApiService.showRegisterPage) {
      action = [
        <Link
          to="/auth/register-page"
          style={{ color: roseColor[0], marginRight: "10px" }}
        >
          Register here
        </Link>,
      ];
    } else if (clickAction === "invalid password") {
      action = [
        <Link
          to="/auth/reset-password-email"
          style={{ color: roseColor[0], marginRight: "10px" }}
        >
          Forgot your password?
        </Link>,
      ];
    }
  }

  if (close !== undefined) {
    action.push(
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={click}
      >
        <Close className={classes.close} />
      </IconButton>
    );
  }
  if (button) {
    action.push(
      <Button color="primary" variant="contained" onClick={click}>
        {button}
      </Button>
    );
  }
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === "info",
    [classes.successIcon]: color === "success",
    [classes.warningIcon]: color === "warning",
    [classes.dangerIcon]: color === "danger",
    [classes.primaryIcon]: color === "primary",
    [classes.roseIcon]: color === "rose",
  });
  return (
    <Snack
      message={
        <div>
          {icon !== undefined ? <props.icon className={iconClasses} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      classes={{
        root: classes.root + " " + classes[color],
        message: classes.message,
      }}
      action={action}
    />
  );
}
