import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import DescriptionIcon from "@material-ui/icons/Description";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ApiService from "api/ApiService.js";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import Button from "components/CustomButtons/Button.js";
import Datetime from "react-datetime";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { Link } from "react-router-dom";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { formatDate } from "api/utils.js";
import Typography from "@material-ui/core/Typography";
import ReactTable from "react-table";
import "./style.css";
import Big from "big.js";
import { useTranslation } from "react-i18next";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

const Dashboard = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [statistics, setStatistics] = React.useState([]);
  const [statisticsSharedWithMe, setStatisticsSharedWithMe] = React.useState(
    []
  );
  const [fromDate, setFromDate] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [userProfile, setUserProfile] = React.useState({});
  const [exchangeLists, setExchangeLists] = React.useState({});

  const [footerStatistics, setFooterStatistics] = React.useState({});
  const [footerSharedStatistics, setFooterSharedStatistics] = React.useState(
    {}
  );

  const lang = navigator.language;

  const handleFilterStats = async () => {
    try {
      const filter = {
        dateFromString:
          typeof fromDate === "string" && fromDate.length > 0
            ? fromDate
            : undefined,
        dateToString:
          typeof toDate === "string" && toDate.length > 0 ? toDate : undefined,
        dateFormat: "%Y-%m-%dT%H:%M:%S.%LZ",
      };

      const metadataStatistics = await calculateMetadataStatistics(filter);
      setStatistics(postProcessStatistics(metadataStatistics));

      filter.excludeOwner = true;
      const signatureStatistics = await calculateMetadataStatistics(filter);
      setStatisticsSharedWithMe(postProcessStatistics(signatureStatistics));
    } catch (e) {
      console.error(e);
    }
  };

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#dbb686"} loading={loading} />
      </div>
    );
  };

  const calculateMetadataStatistics = async ({
    parentType = "PACKAGE",
    dateFromString = undefined,
    dateToString = undefined,
    dateFormat = "%Y-%m-%dT%H:%M:%S.%LZ",
    signal = undefined,
    excludeOwner = false,
  } = {}) => {
    const params = {};
    if (parentType) {
      params.parentType = parentType;
    }
    params.filter = [
      {
        $or: [
          { name: "Customer organization number" },
          { name: "Invoice date" },
          { name: "Amount Excluding VAT" },
          { name: "Currency" },
        ],
      },
    ];
    if (excludeOwner) params.excludeOwner = excludeOwner;

    const dateFilter = [];
    if (dateFromString || dateToString) {
      const dateFilteringExpression = [];
      if (dateFromString) {
        dateFilteringExpression.push({
          $gte: [
            "$Invoice date",
            {
              $dateFromString: {
                dateString: dateFromString,
                format: dateFormat,
              },
            },
          ],
        });
      }
      if (dateToString) {
        dateFilteringExpression.push({
          $lte: [
            "$Invoice date",
            {
              $dateFromString: {
                dateString: dateToString,
                format: dateFormat,
              },
            },
          ],
        });
      }
      dateFilter.push({
        $match: {
          $expr: {
            $and: dateFilteringExpression,
          },
        },
      });
    }
    const packageFilters = [
      { "package.status": { $ne: "EXPIRED" } },
      { "package.status": { $ne: "DELETED" } },
      { "package.flags.name": { $ne: "bin" } },
    ];
    if (!ApiService.production) {
      packageFilters.push({ "package.flags.name": "abendum" });
    }
    params.aggregate = [
      {
        $group: {
          _id: "$parentId",
          metadata: {
            $push: {
              k: "$name",
              v: "$metadata",
            },
          },
        },
      },
      {
        $lookup: {
          from: "packages",
          localField: "_id",
          foreignField: "_id",
          as: "package",
        },
      },
      {
        $match: {
          $and: [...packageFilters],
        },
      },
      {
        $lookup: {
          from: "signatures",
          localField: "_id",
          foreignField: "parentId",
          as: "signatures",
        },
      },
      {
        $project: {
          metadata: 1,
          signatures: {
            $setUnion: ["$signatures.sender"],
          },
        },
      },
      {
        $addFields: {
          signatures: [
            {
              k: t("signatures"),
              v: { $size: "$signatures" },
            },
          ],
        },
      },
      {
        $project: {
          metadata: { $concatArrays: ["$metadata", "$signatures"] },
        },
      },
      {
        $project: {
          metadata: {
            $arrayToObject: "$metadata",
          },
        },
      },
      {
        $replaceRoot: {
          newRoot: "$metadata",
        },
      },
      {
        $project: {
          "Customer organization number": 1,
          Currency: 1,
          Signatures: 1,
          "Invoice date": {
            $dateFromString: {
              dateString: "$Invoice date",
              format: "%Y-%m-%dT%H:%M:%S.%LZ",
              onError: null,
            },
          },
          Amount: {
            $convert: {
              input: "$Amount Excluding VAT",
              to: "decimal",
              onNull: {
                $toDecimal: "0",
              },
              onError: {
                $toDecimal: "0",
              },
            },
          },
        },
      },
      ...dateFilter,
      {
        $addFields: {
          Confirmed: {
            $cond: { if: { $gte: ["$Signatures", 2] }, then: 1, else: 0 },
          },
          "Confirmed amount": {
            $cond: {
              if: { $gte: ["$Signatures", 2] },
              then: "$Amount",
              else: { $toDecimal: "0" },
            },
          },
        },
      },
      {
        $group: {
          _id: {
            "Customer organization number": "$Customer organization number",
            Currency: "$Currency",
          },
          "Total amount": {
            $sum: "$Amount",
          },
          "Confirmed amount": {
            $sum: "$Confirmed amount",
          },
          "Total invoices": {
            $sum: 1,
          },
          "Confirmed invoices": {
            $sum: "$Confirmed",
          },
        },
      },
    ];
    if (excludeOwner) {
      return await ApiService.calculateSignatureStatistics(params, signal);
    } else {
      return await ApiService.calculateMetadataStatistics(params, signal);
    }
  };

  const postProcessStatistics = (metadataStatistics) => {
    const statistics = metadataStatistics.map((entry) => {
      return {
        confirmedAmount: entry["Confirmed amount"]["$numberDecimal"],
        confirmedInvoices: entry["Confirmed invoices"],
        amountExcludingVAT: entry["Total amount"]["$numberDecimal"],
        totalInvoices: entry["Total invoices"],
        customerOrgNr: entry["_id"]["Customer organization number"]
          ? entry["_id"]["Customer organization number"]
          : "",
        currency: entry["_id"]["Currency"] ? entry["_id"]["Currency"] : "NOK",
        dummyString: "",
      };
    });

    return statistics;
  };

  React.useEffect(() => {
    if (!isObjectEmpty(exchangeLists) && statistics.length > 0) {
      const currencySet = new Set();
      statistics.forEach(({ currency }) => {
        currencySet.add(currency);
      });
      const numberOfCurrencies = currencySet.size;

      const footer = {
        confirmedAmount: statistics
          .reduce((sum, { confirmedAmount, currency }) => {
            if (numberOfCurrencies > 1) {
              const divider = new Big(
                exchangeLists.currencyExchangeRate[currency]
              );
              const amount = new Big(confirmedAmount);
              return amount.div(divider).plus(sum);
            } else {
              const amount = new Big(confirmedAmount);
              return amount.plus(sum);
            }
          }, new Big("0"))
          .toFixed(2)
          .toLocaleString(navigator.language),
        confirmedInvoices: statistics.reduce(
          (sum, { confirmedInvoices }) => sum + confirmedInvoices,
          0
        ),
        amountExcludingVAT: statistics
          .reduce((sum, { amountExcludingVAT, currency }) => {
            if (numberOfCurrencies > 1) {
              const divider = new Big(
                exchangeLists.currencyExchangeRate[currency]
              );
              const amount = new Big(amountExcludingVAT);
              return amount.div(divider).plus(sum);
            } else {
              const amount = new Big(amountExcludingVAT);
              return amount.plus(sum);
            }
          }, new Big("0"))
          .toFixed(2)
          .toString(),
        totalInvoices: statistics.reduce(
          (sum, { totalInvoices }) => sum + totalInvoices,
          0
        ),
        customerOrgNr: "SUM",
        currency:
          numberOfCurrencies > 1
            ? exchangeLists.pricingCurrency
            : statistics[0].currency,
        dummyString: "",
      };
      setFooterStatistics(footer);
    }
  }, [exchangeLists, statistics]);

  React.useEffect(() => {
    if (!isObjectEmpty(exchangeLists) && statisticsSharedWithMe.length > 0) {
      const currencySet = new Set();

      statisticsSharedWithMe.forEach(({ currency }) => {
        currencySet.add(currency);
      });
      const numberOfCurrencies = currencySet.size;

      const footer = {
        confirmedAmount: statisticsSharedWithMe
          .reduce((sum, { confirmedAmount, currency }) => {
            if (numberOfCurrencies > 1) {
              const divider = new Big(
                exchangeLists.currencyExchangeRate[currency]
              );
              const amount = new Big(confirmedAmount);
              return amount.div(divider).plus(sum);
            } else {
              const amount = new Big(confirmedAmount);
              return amount.plus(sum);
            }
          }, new Big("0"))
          .toFixed(2)
          .toString(),
        confirmedInvoices: statisticsSharedWithMe.reduce(
          (sum, { confirmedInvoices }) => sum + confirmedInvoices,
          0
        ),
        amountExcludingVAT: statisticsSharedWithMe
          .reduce((sum, { amountExcludingVAT, currency }) => {
            if (numberOfCurrencies > 1) {
              const divider = new Big(
                exchangeLists.currencyExchangeRate[currency]
              );
              const amount = new Big(amountExcludingVAT);
              return amount.div(divider).plus(sum);
            } else {
              const amount = new Big(amountExcludingVAT);
              return amount.plus(sum);
            }
          }, new Big("0"))
          .toFixed(2)
          .toString(),
        totalInvoices: statisticsSharedWithMe.reduce(
          (sum, { totalInvoices }) => sum + totalInvoices,
          0
        ),
        customerOrgNr: "SUM",
        currency:
          numberOfCurrencies > 1
            ? exchangeLists.pricingCurrency
            : statisticsSharedWithMe[0].currency,
        dummyString: "",
      };
      setFooterSharedStatistics(footer);
    }
  }, [exchangeLists, statisticsSharedWithMe]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      setLoading(true);
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        setUserProfile(userProfile);
        if (userProfile.validatedEmail && userProfile.validatedDocuments) {
          // outbound
          const exchangeLists = await ApiService.getExhangeRates();
          setExchangeLists(exchangeLists);

          const metadataStatistics = await calculateMetadataStatistics({
            signal,
          });
          setStatistics(postProcessStatistics(metadataStatistics));
          const signatureStatistics = await calculateMetadataStatistics({
            signal,
            excludeOwner: true,
          });
          setStatisticsSharedWithMe(postProcessStatistics(signatureStatistics));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={6} lg={7}></GridItem>
                  <GridItem xs={12} sm={3} md={2} lg={2}>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: t("from-date") }}
                      value={
                        fromDate !== null && fromDate.length > 0
                          ? formatDate(fromDate)
                          : null
                      }
                      onChange={(e) =>
                        e !== null
                          ? setFromDate(moment(e).toISOString())
                          : setFromDate("")
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={2} lg={2}>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: t("to-date") }}
                      value={
                        toDate !== null && toDate.length > 0
                          ? formatDate(toDate)
                          : null
                      }
                      onChange={(e) =>
                        e !== null
                          ? setToDate(moment(e).toISOString())
                          : setToDate("")
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={2} md={2} lg={1}>
                    <Button
                      style={{ float: "right" }}
                      round
                      simple
                      color="rose"
                      className="remove"
                      onClick={handleFilterStats}
                    >
                      {t("filter")}
                    </Button>
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                      <CardHeader color="lightBlue" stats icon>
                        <CardIcon color="lightBlue">
                          <VerticalAlignBottomIcon />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          {" "}
                          Amount including VAT
                        </p>
                        <h3 className={classes.cardTitle}>
                          {statistics.inboundWithVAT}
                        </h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          {statistics.totalInbound} Inbound invoices
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                      <CardHeader color="lightBlue" stats icon>
                        <CardIcon color="lightBlue">
                          <ArrowDownwardIcon />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Amount excluding VAT
                        </p>
                        <h3 className={classes.cardTitle}>
                          {statistics.inboundWithoutVAT}
                        </h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          {statistics.totalInbound} Inbound invoices
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                      <CardHeader color="primary" stats icon>
                        <CardIcon color="primary">
                          <VerticalAlignTopIcon />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Amount including VAT
                        </p>
                        <h3 className={classes.cardTitle}>
                          {statistics.outboundWithVAT}
                        </h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          {statistics.totalOutbound} Outbound invoices
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                      <CardHeader color="primary" stats icon>
                        <CardIcon color="primary">
                          <ArrowUpwardIcon />
                        </CardIcon>
                        <p className={classes.cardCategory}>
                          Amount excluding VAT
                        </p>
                        <h3 className={classes.cardTitle}>
                          {statistics.outboundWithoutVAT}
                        </h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          {statistics.totalOutbound} Outbound invoices
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer> */}
                <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="h5">
                      {t("invoice-dashboard")}
                    </Typography>
                  </GridItem>

                  <GridItem xs={12}>
                    <Card>
                      <CardHeader color="lightBlue" icon>
                        <CardIcon color="lightBlue">
                          <DescriptionIcon />
                        </CardIcon>
                        <h4
                          className={classes.cardIconTitle}
                          style={{ marginTop: "35px" }}
                        >
                          {t("received-invoices")}
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {statisticsSharedWithMe.length > 0 && (
                          <ReactTable
                            style={{ overflow: "hidden" }}
                            data={statisticsSharedWithMe}
                            sortable={true}
                            filterable={true}
                            resizable={false}
                            columns={[
                              {
                                Header: t("supplier-organization-number"),
                                accessor: "customerOrgNr",
                                minWidth: 260,
                                Footer: t("sum"),
                              },
                              {
                                Header: t("currency"),
                                accessor: "currency",
                                minWidth: 100,
                                Footer: (
                                  <div>{footerSharedStatistics.currency}</div>
                                ),
                              },
                              {
                                Header: t("total-invoices"),
                                accessor: "totalInvoices",
                                minWidth: 130,
                                headerStyle: { textAlign: "right" },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {row.value}
                                  </div>
                                ),
                                Footer: (
                                  <div style={{ textAlign: "right" }}>
                                    {footerSharedStatistics.totalInvoices}
                                  </div>
                                ),
                              },
                              {
                                Header: t("confirmed-invoices"),
                                accessor: "confirmedInvoices",
                                minWidth: 175,
                                headerStyle: { textAlign: "right" },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {row.value}
                                  </div>
                                ),
                                Footer: (
                                  <div style={{ textAlign: "right" }}>
                                    {footerSharedStatistics.confirmedInvoices}
                                  </div>
                                ),
                              },
                              {
                                Header: t("total-amount"),
                                accessor: "amountExcludingVAT",
                                minWidth: 135,

                                headerStyle: { textAlign: "right" },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {parseFloat(row.value).toLocaleString(lang)}
                                  </div>
                                ),
                                Footer: (
                                  <div style={{ textAlign: "right" }}>
                                    {parseFloat(
                                      footerSharedStatistics.amountExcludingVAT
                                    ).toLocaleString(lang)}
                                  </div>
                                ),
                              },
                              {
                                Header: t("confirmed-amount"),
                                accessor: "confirmedAmount",
                                minWidth: 170,

                                headerStyle: { textAlign: "right" },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {parseFloat(row.value).toLocaleString(lang)}
                                  </div>
                                ),
                                Footer: (
                                  <div style={{ textAlign: "right" }}>
                                    {parseFloat(
                                      footerSharedStatistics.confirmedAmount
                                    ).toLocaleString(lang)}
                                  </div>
                                ),
                              },
                              {
                                Header: "",
                                accessor: "dummyString",
                                width: 1,
                              },
                            ]}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            defaultPageSize={statisticsSharedWithMe.length}
                            nextText={t("next")}
                            previousText={t("previous")}
                            loadingText={t("loading")}
                            noDataText={t("no-data")}
                            pageText={t("page")}
                            ofText={t("of")}
                            rowsText={t("rows")}
                          />
                        )}
                      </CardBody>
                      {/* <CardHeader color="lightBlue" icon>
                        <CardIcon color="lightBlue">
                          <DescriptionIcon />
                        </CardIcon>
                        <h4
                          className={classes.cardIconTitle}
                          style={{ marginTop: "35px" }}
                        >
                          Inbound invoices
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {!isObjectEmpty(statistics) && (
                          <Table
                            tableHead={[
                              "Supplier organization number",
                              "Total invoices",
                              "Amount excluding VAT",
                            ]}
                            tableData={statistics.inboundByCustomer.map(
                              (item) => {
                                return [
                                  item.customerOrgNr,
                                  item.totalInvoices,
                                  item.amountExcludingVAT,
                                ];
                              }
                            )}
                            customCellClasses={[
                              classes.left,
                              classes.right,
                              classes.right,
                              classes.right,
                            ]}
                            customClassesForCells={[0, 1, 2]}
                            customHeadCellClasses={[
                              classes.left,
                              classes.right,
                              classes.right,
                            ]}
                            customHeadClassesForCells={[0, 1, 2]}
                          />
                        )}
                      </CardBody> */}
                      <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                          <InsertDriveFileIcon />
                        </CardIcon>
                        <h4
                          className={classes.cardIconTitle}
                          style={{ marginTop: "35px" }}
                        >
                          {t("sent-invoices")}
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {statistics.length > 0 && (
                          <ReactTable
                            data={statistics}
                            sortable={true}
                            multiSort={false}
                            filterable={true}
                            resizable={false}
                            columns={[
                              {
                                Header: t("customer-organization-number"),
                                accessor: "customerOrgNr",
                                minWidth: 260,
                                Footer: t("sum"),
                              },
                              {
                                Header: t("currency"),
                                accessor: "currency",
                                minWidth: 100,
                                Footer: <div>{footerStatistics.currency}</div>,
                              },
                              {
                                Header: t("total-invoices"),
                                accessor: "totalInvoices",
                                headerStyle: { textAlign: "right" },
                                minWidth: 130,
                                Cell: (row) => (
                                  <div
                                    style={{
                                      textAlign: "right",
                                    }}
                                  >
                                    {row.value}
                                  </div>
                                ),
                                Footer: (
                                  <div style={{ textAlign: "right" }}>
                                    {footerStatistics.totalInvoices}
                                  </div>
                                ),
                              },
                              {
                                Header: t("confirmed-invoices"),
                                accessor: "confirmedInvoices",
                                minWidth: 175,
                                headerStyle: { textAlign: "right" },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {row.value}
                                  </div>
                                ),
                                Footer: (
                                  <div style={{ textAlign: "right" }}>
                                    {footerStatistics.confirmedInvoices}
                                  </div>
                                ),
                              },
                              {
                                Header: t("total-amount"),
                                accessor: "amountExcludingVAT",
                                minWidth: 135,
                                headerStyle: { textAlign: "right" },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {parseFloat(row.value).toLocaleString(lang)}
                                  </div>
                                ),
                                Footer: (
                                  <div style={{ textAlign: "right" }}>
                                    {parseFloat(
                                      footerStatistics.amountExcludingVAT
                                    ).toLocaleString(lang)}
                                  </div>
                                ),
                              },
                              {
                                Header: t("confirmed-amount"),
                                accessor: "confirmedAmount",
                                minWidth: 170,
                                headerStyle: {
                                  textAlign: "right",
                                },
                                Cell: (row) => (
                                  <div style={{ textAlign: "right" }}>
                                    {parseFloat(row.value).toLocaleString(lang)}
                                  </div>
                                ),
                                Footer: (
                                  <div style={{ textAlign: "right" }}>
                                    {parseFloat(
                                      footerStatistics.confirmedAmount
                                    ).toLocaleString(lang)}
                                  </div>
                                ),
                              },
                              {
                                Header: "",
                                accessor: "dummyString",
                                width: 1,
                              },
                            ]}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            defaultPageSize={statistics.length}
                            nextText={t("next")}
                            previousText={t("previous")}
                            loadingText={t("loading")}
                            noDataText={t("no-data")}
                            pageText={t("page")}
                            ofText={t("of")}
                            rowsText={t("rows")}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12}>
                    <Card></Card>
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                  <GridItem xs={12}>
                    <Typography variant="h5">Shared with me Statistics</Typography>
                  </GridItem>
                  <GridItem xs={12}>
                    <Card>
                      <CardHeader color="lightBlue" icon>
                        <CardIcon color="lightBlue">
                          <DescriptionIcon />
                        </CardIcon>
                        <h4
                          className={classes.cardIconTitle}
                          style={{ marginTop: "35px" }}
                        >
                          Inbound invoices
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {!isObjectEmpty(statisticsSharedWithMe) && (
                          <Table
                            tableHead={[
                              "Supplier organization number",
                              "Total invoices",
                              "Amount excluding VAT",
                            ]}
                            tableData={statisticsSharedWithMe.inboundByCustomer.map(
                              (item) => {
                                return [
                                  item.customerOrgNr,
                                  item.totalInvoices,
                                  item.amountExcludingVAT,
                                ];
                              }
                            )}
                            customCellClasses={[
                              classes.left,
                              classes.right,
                              classes.right,
                              classes.right,
                            ]}
                            customClassesForCells={[0, 1, 2]}
                            customHeadCellClasses={[
                              classes.left,
                              classes.right,
                              classes.right,
                            ]}
                            customHeadClassesForCells={[0, 1, 2]}
                          />
                        )}
                      </CardBody>
                      <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                          <InsertDriveFileIcon />
                        </CardIcon>
                        <h4
                          className={classes.cardIconTitle}
                          style={{ marginTop: "35px" }}
                        >
                          Outbound invoices
                        </h4>
                      </CardHeader>
                      <CardBody>
                        {!isObjectEmpty(statisticsSharedWithMe) && (
                          <Table
                            tableHead={[
                              "Customer organization number",
                              "Total invoices",
                              "Amount excluding VAT",
                            ]}
                            tableData={statisticsSharedWithMe.outboundByCustomer.map(
                              (item) => {
                                return [
                                  item.customerOrgNr,
                                  item.totalInvoices,
                                  item.amountExcludingVAT,
                                ];
                              }
                            )}
                            customCellClasses={[
                              classes.left,
                              classes.right,
                              classes.right,
                              classes.right,
                            ]}
                            customClassesForCells={[0, 1, 2]}
                            customHeadCellClasses={[
                              classes.left,
                              classes.right,
                              classes.right,
                            ]}
                            customHeadClassesForCells={[0, 1, 2]}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer> */}
              </>
            )}
          {!isObjectEmpty(userProfile) &&
            (!userProfile.validatedDocuments ||
              !userProfile.validatedEmail) && (
              <>
                <Card pricing plain>
                  <CardBody pricing>
                    <div className={classes.icon}>
                      {!userProfile.requestDocumentsValidation ? (
                        <SupervisedUserCircleIcon
                          className={classes.iconPrimary}
                        />
                      ) : (
                        <HourglassEmptyIcon className={classes.iconPrimary} />
                      )}
                    </div>
                    <h5
                      className={`${classes.cardTitle} ${classes.marginTop30}`}
                    >
                      {!userProfile.requestDocumentsValidation
                        ? t("in-order-to-proceed")
                        : t("waiting-for-document-validation")}
                    </h5>
                    {!userProfile.requestDocumentsValidation ? (
                      <p className={classes.cardDescription}>
                        {!userProfile.validatedEmail && t("validate-email")}
                        {!userProfile.validatedEmail &&
                          (!userProfile.validatedDocuments ? t("and") : ".")}
                        {!userProfile.validatedDocuments &&
                          t("request-a-document-validation")}
                      </p>
                    ) : (
                      <p className={classes.cardDescription}>
                        {!userProfile.validatedEmail &&
                          t("please-validate-your-email")}
                      </p>
                    )}
                    <Link
                      to="/admin/user-page"
                      style={{ color: primaryColor[0] }}
                    >
                      {t("go-to-user-profile")}
                    </Link>
                  </CardBody>
                </Card>
              </>
            )}
        </>
      )}
    </>
  );
};

export default Dashboard;
