import React from "react";
import GridItem from "components/Grid/GridItem.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import extendedTablesStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { formatDate } from "api/utils";
import { useTranslation } from "react-i18next";
import { grayColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme) => ({
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...extendedTablesStyles,
}));

const AccessRightsTable = ({
  accessRights = [],
  handleDeleteAccess,
  isShared = false,
  grid = 12,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <GridItem xs={grid}>
      <Table
        tableHead={
          !isShared
            ? [t("date"), t("email"), t("actions")]
            : [t("date"), t("email")]
        }
        tableData={accessRights.map((item) => {
          return !isShared
            ? [
                formatDate(item.createdAt),
                item.email,
                <Tooltip title={t("remove-access")}>
                  <Button
                    color="danger"
                    simple
                    className={classes.actionButton}
                    key={item.name}
                    onClick={(e) =>
                      handleDeleteAccess(
                        e,
                        item.parentId,
                        item._id,
                        item.parentType
                      )
                    }
                  >
                    <Close className={classes.icon} />
                  </Button>
                </Tooltip>,
              ]
            : [formatDate(item.createdAt), item.email];
        })}
        customCellClasses={[classes.left, classes.left, classes.right]}
        customClassesForCells={[0, 1, 2]}
        customHeadCellClasses={[classes.left, classes.left, classes.right]}
        customHeadClassesForCells={[0, 1, 2]}
      />
    </GridItem>
  );
};

export default AccessRightsTable;
