import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Table/Table.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import CheckIcon from "@material-ui/icons/Check";
import LinkIcon from "@material-ui/icons/Link";
import Tooltip from "@material-ui/core/Tooltip";
import { formatDate } from "api/utils";
import Button from "components/CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import ApiService from "api/ApiService.js";
import ModalComponent from "components/ModalComponent.js";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function SignaturesTable({
  data,
  currentHash,
  handleVerifyHash,
  handleDeleteSignature,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [lookupUsers, setLookupUsers] = React.useState({});
  const [openBCModal, setOpenBCModal] = React.useState(false);
  const [documentDetails, setDocumentDetails] = React.useState({});

  const handleCloseBCModal = () => {
    setOpenBCModal(false);
  };

  const handleOpenBCModal = (item) => {
    setDocumentDetails(item);
    setOpenBCModal(true);
  };

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && isObjectEmpty(lookupUsers)) {
      const getUsers = async () => {
        const responseData = await ApiService.getAllUsers({});
        const users = {};
        for (const entry of responseData) {
          users[entry.address] = entry.user.email;
        }
        setLookupUsers(users);
      };

      getUsers();
    }
    return () => {
      isMounted = false;
    };
  }, [lookupUsers]);

  return (
    <>
      {data !== undefined ? (
        <Table
          tableHead={[t("date"), t("user"), t("metadata-hash"), t("actions")]}
          tableData={data.map((item) => {
            const userAddress = lookupUsers[item.sender];
            return [
              formatDate(item.createdAt),
              `${userAddress}`,
              item.metadataHash,
              [
                <Tooltip title={t("hash-information")}>
                  <Button
                    color="primary"
                    simple
                    className={classes.actionButton}
                    key={item.name}
                    onClick={(e) =>
                      handleVerifyHash(
                        item._id,
                        item.parentId,
                        item.type,
                        item.metadataHash,
                        item.metadata
                      )
                    }
                  >
                    <CheckIcon className={classes.icon} />
                  </Button>
                </Tooltip>,
                <Tooltip title={t("view-on-chain")}>
                  <Button
                    color="info"
                    simple
                    className={classes.actionButton}
                    key={item.name}
                    onClick={() => handleOpenBCModal(item)}
                  >
                    <LinkIcon className={classes.icon} />
                  </Button>
                </Tooltip>,
                <Tooltip title={t("remove-access")}>
                  <Button
                    color="danger"
                    simple
                    className={classes.actionButton}
                    key={item.name}
                    onClick={() =>
                      handleDeleteSignature(item._id, item.parentId, item.type)
                    }
                  >
                    <Close className={classes.icon} />
                  </Button>
                </Tooltip>,
              ],
            ];
          })}
          customCellClasses={[
            classes.left,
            classes.left,
            classes.right,
            classes.right,
            classes.right,
          ]}
          customClassesForCells={[0, 1, 2, 3, 4]}
          customHeadCellClasses={[
            classes.left,
            classes.left,
            classes.right,
            classes.right,
            classes.right,
          ]}
          customHeadClassesForCells={[0, 1, 2, 3, 4]}
        />
      ) : (
        <div style={{ textAlign: "center", marginTop: "4rem" }}>
          {t("no-signatures-yet")}
        </div>
      )}

      <ModalComponent
        open={openBCModal}
        handleClose={handleCloseBCModal}
        dialogTitle={t("view-on-chain")}
        maxWidth="xs"
        dialogContent={
          <div>
            {documentDetails.transactionStatus === "PREPARED" ? (
              <p>{t("transaction-is-presigned-but-not-on-blockchain")}</p>
            ) : (
              <div style={{ textAlign: "center" }}>
                <QRCode
                  size={200}
                  value={`https://whatsonchain.com/tx/${documentDetails.transactionId}`}
                />
                <div style={{ textAlign: "center", marginTop: "2rem" }}>
                  <a
                    href={`https://whatsonchain.com/tx/${documentDetails.transactionId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("link")}
                  </a>
                </div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
}
