import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Big from "big.js";
import {
  lightBlue,
  infoColor,
  successColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react";

const ItemExpansionPanelSummary = ({ item, index }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // helper functions to calculate % of signatures and status
  //   const getSignatures = () => {
  //     if (item.entry.length === 0) {
  //       return `0% ${t("confirmed")}`;
  //     }
  //     const signatures = item.entry.reduce(
  //       (prev, curr) => prev + curr.signatures.length,
  //       0
  //     );
  //     const totalSignatures = item.entry.length * 2;
  //     let confirmed = "0";
  //     if (signatures > 0) {
  //       confirmed = (signatures / totalSignatures) * 100;
  //     }
  //     return `${confirmed.toFixed(2)}% ${t("confirmed")}`;
  //   };

  const getAmountConfirmed = () => {
    if (item.entry.length === 0) {
      return `0% ${t("confirmed")}`;
    }

    const confirmedValues = item.entry
      .filter((item) => item.flags.length === 2 && item.signatures.length === 2)
      .reduce((prev, curr) => {
        const value = new Big(curr.metadata.value);
        return value.plus(prev);
      }, new Big(0));

    let confirmed = new Big(0);
    const total = new Big(item.metadata.total);
    if (confirmedValues > 0) {
      confirmed = confirmedValues.div(total).times(100);
    }

    return `${Math.ceil(confirmed)}% ${t("confirmed")}`;
  };

  const getStatus = () => {
    if (item.entry.length === 0) {
      return t("status-no-data");
    }
    const reversedEntries = item.entry.filter(
      (entry) => entry.metadata.reverse === true
    );
    if (reversedEntries.length === item.entry.length) {
      return t("status-all-entries-are-reversed");
    }
    const oneSignature = item.entry.find(
      (entry) => entry.signatures.length === 1
    );
    if (oneSignature) {
      return t("status-waiting-for-signatures");
    } else {
      return t("status-signed");
    }
  };

  return (
    <>
      <div
        className={`${classes.panelSummaryColumn} ${classes.panelSummaryMarginTop}`}
      >
        <Typography>{item.metadata.name}</Typography>
      </div>
      <div
        className={`${classes.panelSummaryColumn10} ${classes.panelSummaryMarginTop}`}
      >
        <Typography>{item.metadata.total}</Typography>
      </div>
      <div
        className={`${classes.panelSummaryColumn10} ${classes.panelSummaryMarginTop}`}
      >
        <Typography>{item.metadata.unit}</Typography>
      </div>
      <div
        className={`${classes.panelSummaryColumn10} ${classes.panelSummaryMarginTop}`}
      >
        <Typography>{item.metadata.framework}</Typography>
      </div>
      <div
        className={`${classes.panelSummaryColumn10} ${classes.panelSummaryMarginTop}`}
      >
        <Typography>{item.metadata.indexCode}</Typography>
      </div>
      <div
        className={`${classes.panelSummaryColumn} ${classes.panelSummaryMarginTop}`}
      >
        <Typography>
          {item.metadata.confirmations
            ? item.metadata.confirmations
            : getAmountConfirmed()}
        </Typography>
      </div>
      <div
        className={`${classes.panelSummaryColumn} ${classes.panelSummaryMarginTop}`}
      >
        <Typography>
          {item.metadata.status ? item.metadata.status : getStatus()}
        </Typography>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  panelSummaryHeading: {
    fontSize: "1rem",
    color: lightBlue[1],
    fontWeight: "600",
  },
  panelSummarySecondaryHeading: {
    fontSize: "1rem",
    color: infoColor[4],
  },
  panelSummaryColumn: {
    flexBasis: "20%",
    marginLeft: "8px",
    marginRight: "8px",
  },
  panelSummaryColumn10: {
    flexBasis: "10%",
    marginLeft: "8px",
    marginRight: "8px",
  },
  panelSummaryMarginTop: {
    marginTop: "10px",
  },
  panelSummaryLeftIcon: {
    marginRight: "10px",
  },
  panelSummaryInline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    flexWrap: "no-wrap",
    justifyContent: "flex-end",
  },
  panelSummaryButton: {
    marginLeft: "10px",
  },
  panelSummaryTernaryHeading: {
    color: successColor[0],
  },
  panelSummaryHeadingDateGrey: {
    fontSize: "1rem",
    fontWeight: "300",
    color: grayColor[1],
  },
}));

export default ItemExpansionPanelSummary;
