import React from "react";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  primaryColor,
  successColor,
} from "assets/jss/material-dashboard-pro-react";
import { dangerColor } from "assets/jss/material-dashboard-pro-react";
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    maxWidth: "90vw",
  },
  success: {
    backgroundcolor: successColor[0],
  },
  error: {
    backgroundColor: dangerColor[7],
  },
  info: {
    backgroundColor: successColor[3],
  },
  warning: {
    backgroundcolor: primaryColor[0],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <div>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span
            id="client-snackbar"
            className={`${classes.message} ${classes.root}`}
          >
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </div>
  );
}

const SnackBarComponent = ({
  open,
  handleClose,
  variant,
  message,
  position = "top",
}) => {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: matches ? "top" : position,
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        autoHideDuration={4000}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={variant}
          message={message}
        />
      </Snackbar>
    </div>
  );
};

export default SnackBarComponent;
