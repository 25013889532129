import ApiService from "api/ApiService";
import abendumLogo from "assets/img/Abendum-logo.png";

// const bgImage = {
//   abendum: abendumBgImage,
// };

const logo = {
  abendum: abendumLogo,
};

// export const getBgImage = () => {
//   switch (ApiService.theme) {
//     case ApiService.themes.abendum:
//       return bgImage.abendum;
//     default:
//       break;
//   }
// };

export const getMiniLogo = () => {
  switch (ApiService.theme) {
    case ApiService.themes.abendum:
      return logo.abendum;
    default:
      break;
  }
};

export const getLogo = () => {
  switch (ApiService.theme) {
    case ApiService.themes.abendum:
      return logo.abendum;
    default:
      break;
  }
};

const CompanyTheme = {
  // getBgImage,
  getLogo,
  getMiniLogo,
};

export default CompanyTheme;
