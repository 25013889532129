import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { formatDate } from "api/utils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
const AddMetadataInputs = ({ currentState, handleSetState, handleAddData }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <GridContainer spacing={1}>
      {Object.entries(currentState).map(([key, value], i) => {
        if (i === 11 || i === 12 || i === 13) {
          return (
            <GridItem xs={12} sm={12} key={i}>
              <CustomInput
                labelText={t(value.translation)}
                id={key}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    handleSetState(e.target.value, key);
                  },
                  value: value.value,
                  rows: 3,
                }}
              />
            </GridItem>
          );
        } else if (i === 7) {
          return (
            <GridItem xs={12} sm={12} md={6} key={i}>
              <FormControl fullWidth style={{ paddingTop: "24px" }}>
                <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: t(value.translation) }}
                  value={
                    value.value !== null && value.value.length > 0
                      ? formatDate(value.value)
                      : null
                  }
                  onChange={(e) => handleSetState(moment(e).toISOString(), key)}
                />
              </FormControl>
            </GridItem>
          );
        } else if (i === 2 || i === 3 || i === 4) {
          return (
            <GridItem xs={12} sm={12} md={6} key={i}>
              <CustomInput
                labelText={t(value.translation)}
                id={key}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    handleSetState(e.target.value, key);
                  },
                  value: value.value,
                  type: "text",
                }}
              />
            </GridItem>
          );
        } else if (i === 5) {
          return (
            <GridItem xs={12} sm={12} md={6} key={i}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  {t("currency")}
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={value.value}
                  onChange={(e) => {
                    handleSetState(e.target.value, key);
                  }}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {t("currency")}
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="AUD"
                  >
                    AUD
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="CZK"
                  >
                    CZK
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="DKK"
                  >
                    DKK
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="HUF"
                  >
                    HUF
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="JPY"
                  >
                    JPY
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="NOK"
                  >
                    NOK
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="SEK"
                  >
                    SEK
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="CHF"
                  >
                    CHF
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="GBP"
                  >
                    GBP
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="USD"
                  >
                    USD
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="BAM"
                  >
                    BAM
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="EUR"
                  >
                    EUR
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="PLN"
                  >
                    PLN
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          );
        } else {
          return (
            <GridItem
              key={i}
              xs={12}
              sm={12}
              md={
                i === 8
                  ? 12
                  : i === 0 || i === 1 || i === 6 || i === 9 || i === 10
                  ? 6
                  : i > 13
                  ? 12
                  : 4
              }
            >
              <CustomInput
                labelText={t(value.translation)}
                id={key}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    handleSetState(e.target.value, key);
                  },
                  value: value.value,
                }}
              />
            </GridItem>
          );
        }
      })}

      <div style={{ marginLeft: "15px" }}>
        <Button color="primary" round onClick={handleAddData}>
          {t("save")}
        </Button>
      </div>
    </GridContainer>
  );
};

export default AddMetadataInputs;
