import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import ApiService from "api/ApiService.js";
import { Link } from "react-router-dom";
import SnackBarComponent from "components/SnackBarComponent.js";
import ClipLoader from "react-spinners/ClipLoader";
import Pagination from "@material-ui/lab/Pagination";
import DescriptionIcon from "@material-ui/icons/Description";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { formatDate } from "api/utils";
import extendedTablesStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import FilterSortView from "../../components/FilterSortView";
import ReactTable from "react-table";
import "./style.css";
import { useTranslation } from "react-i18next";
import {
  primaryColor,
  lightBlue,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

const useStyles2 = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      color: whiteColor,
      backgroundcolor: lightBlue[0],
      "&:hover": {
        backgroundcolor: lightBlue[0],
      },

      "&:active": {
        backgroundcolor: lightBlue[0],
      },
    },
  },
  chip: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
  },
  chipColorPrimary: {
    color: primaryColor[0],
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  chipColorSecondary: {
    color: primaryColor[0],
    fontWeight: "bold",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  ...extendedTablesStyles,
}));

const SharedDocuments = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const classes2 = useStyles2();

  const [myFiles, setMyFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [variant, setVariant] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // pagination
  const [sendDataCount, setSendDataCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [lookupUsers, setLookupUsers] = React.useState({});
  const [userProfile, setUserProfile] = React.useState({});

  // list view
  const [showListView, setShowListView] = React.useState(true);
  //filters
  const [filters, setFilters] = React.useState("");
  const [sortBy, setSortBy] = React.useState("createdAt:desc");
  const [filterParams, setFilterParams] = React.useState({});
  const [showFilterSort, setShowFilterSort] = React.useState(false);

  const handleShowFilterSort = () => {
    setShowFilterSort((prevState) => !prevState);
  };

  const handleSetSearchFilters = (value) => {
    setFilters(value);
  };

  const handleShowListView = (bool) => {
    setShowListView(bool);
  };

  const handleSearchTitle = () => {
    if (filters.length > 0) {
      setFilterParams({ title: { $regex: `${filters}`, $options: "/i" } });
    }
  };

  const handleSimple = (event) => {
    setSortBy(event.target.value);
  };

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#dbb686"} loading={loading} />
      </div>
    );
  };

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const getQueryParams = React.useCallback(
    async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);
        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;

      return params;
    },
    [props.location.search]
  );

  const handleRefresh = React.useCallback(
    async (signal = undefined) => {
      const getSharedDocuments = async (signal = undefined, queryParams) => {
        try {
          let params;
          if (showListView) {
            params = {
              userId: queryParams.userId,
              parentType: ApiService.parentTypes.package,
              populateParent: "true",
              sortBy: sortBy,
              populateSignatures: true,
              populateMetadata: true,
            };
          } else {
            params = {
              userId: queryParams.userId,
              parentType: ApiService.parentTypes.package,
              populateParent: "true",
              sortBy: sortBy,
              fromEntry: (currentPage - 1) * pageSize,
              numberOfResults: pageSize,
            };
          }

          if (!ApiService.production) {
            if (!isObjectEmpty(filterParams)) {
              params.filters = JSON.stringify({
                $and: [{ "flags.name": "abendum" }, filterParams],
              });
            } else {
              params.filters = JSON.stringify({ "flags.name": "abendum" });
            }
          } else {
            if (!isObjectEmpty(filterParams)) {
              params.filters = JSON.stringify(filterParams);
            }
          }

          const responseData = await ApiService.getAccessRights(params, signal);
          setMyFiles(
            responseData.filter((file) => file.parentId === undefined)
          );

          if (!showListView) {
            const accessRightsParams = {
              parentType: ApiService.parentTypes.package,
              populateParent: true,
            };

            if (!ApiService.production) {
              accessRightsParams.filters = JSON.stringify({
                "flags.name": "abendum",
              });
            }

            const getSendDataCount = await ApiService.getAccessRightsCount(
              accessRightsParams
            );
            setSendDataCount(getSendDataCount.count);

            const total = Math.ceil(getSendDataCount.count / pageSize);
            setTotalPages(total);
          }
        } catch (e) {
          console.error(e);
          setSnackbarMessage(e.message);
          setVariant("error");
          setOpenSnackbar(true);
        }
      };
      const queryParams = await getQueryParams(signal);
      await getSharedDocuments(signal, queryParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getQueryParams, currentPage, pageSize, sortBy, filterParams, showListView]
  );

  const getIcon = (fileType) => {
    if (fileType === "text/plain") {
      return <i className="far fa-file"></i>;
    } else if (fileType === "text/csv") {
      return <i className="fas fa-file-csv"></i>;
    } else if (fileType === "text/html") {
      return <i className="far fa-file-code"></i>;
    } else if (fileType.startsWith("image")) {
      return <i className="far fa-file-image"></i>;
    } else if (fileType === "application/pdf") {
      return <i className="far fa-file-pdf"></i>;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "application/vnd.oasis.opendocument.text"
    ) {
      return <i className="far fa-file-word"></i>;
    } else if (
      fileType === "application/vnd.ms-powerpoint" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      return <i className="far fa-file-powerpoint"></i>;
    } else if (
      fileType === "application/vnd.ms-excel" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <i className="far fa-file-excel"></i>;
    } else if (
      fileType === "application/zip" ||
      fileType === "application/x-7z-compressed" ||
      fileType === "application/vnd.rar"
    ) {
      return <i className="far fa-file-archive"></i>;
    } else {
      return <i className="far fa-file-alt"></i>;
    }
  };

  // pagination
  const handleChangePage = async (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handleClick = (newPageSize) => {
    const total = Math.ceil(sendDataCount / newPageSize);
    setTotalPages(total);

    let currentIndex;
    if (pageSize > newPageSize) {
      currentIndex = Math.ceil(currentPage / (pageSize / newPageSize));
    } else {
      currentIndex = Math.ceil((pageSize / newPageSize) * currentPage);
    }
    setCurrentPage(currentIndex);
    setPageSize(newPageSize);
  };
  const prepareDataForTable = (myFiles) => {
    const checkConfirmed = (signatures) => {
      if (signatures && signatures.length > 1) {
        const signSet = new Set();
        signatures.map((sig) => signSet.add(sig.sender));
        if (signSet.size >= 2) {
          return t("confirmed");
        } else {
          return t("not-confirmed");
        }
      } else {
        return t("not-confirmed");
      }
    };

    // const setDocumentType = (docType) => {
    //   if (docType === "Outbound invoice") {
    //     return t("sent-invoice");
    //   } else if (docType === "Inbound invoice") {
    //     return t("received-invoice");
    //   } else if (docType === "Other") {
    //     return t("other");
    //   }
    // };

    return myFiles.map((file) => {
      const queryParameters = new URLSearchParams({
        data_id: file.data_id,
        sender: file.sender,
        receiver: file.receiver,
        hash: file.hash,
      }).toString();

      return {
        title: file.title,
        createdAt: formatDate(file.createdAt),
        organizationNumber: file?.metadata?.find(
          (data) => data.name === "Customer organization number"
        )?.metadata,
        invoiceNumber: file?.metadata?.find(
          (data) => data.name === "Invoice number"
        )?.metadata,
        documentType: file?.metadata?.find(
          (data) => data.name === "Document Type"
        )?.metadata,
        confirmedStatus: checkConfirmed(file?.signatures),
        actions: (
          <Button
            onClick={() =>
              props.history.push(
                `/admin/shared-documents/details?${queryParameters}`
              )
            }
            color="rose"
          >
            {t("view")}
          </Button>
        ),
      };
    });
  };

  React.useEffect(() => {
    if (showFilterSort === false) {
      setFilters("");
      setFilterParams({});
    }
  }, [showFilterSort]);

  React.useEffect(() => {
    let isMounted = true;
    const apiOperations = async () => {
      const abortController = new AbortController();
      const signal = abortController.signal;
      setLoading(true);
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        setUserProfile(userProfile);

        userProfile.validatedDocuments &&
          userProfile.validatedEmail &&
          (await handleRefresh(undefined, false));
      } catch (e) {
        console.error(e);
        setSnackbarMessage(e.message);
        setVariant("error");
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
    };
  }, [handleRefresh, showListView]);

  React.useEffect(() => {
    let isMounted = true;
    if (
      isMounted &&
      isObjectEmpty(lookupUsers) &&
      !isObjectEmpty(userProfile) &&
      userProfile.validatedDocuments &&
      userProfile.validatedEmail
    ) {
      const getUsers = async () => {
        const responseData = await ApiService.getAllUsers({});
        const users = {};
        for (const entry of responseData) {
          users[entry.address] = entry.user.email;
        }
        setLookupUsers(users);
      };

      getUsers();
    }
    return () => {
      isMounted = false;
    };
  }, [lookupUsers, userProfile]);

  return (
    <>
      {loading && <Loading />}
      {!loading &&
      !isObjectEmpty(userProfile) &&
      isObjectEmpty(filterParams) &&
      userProfile.validatedDocuments &&
      userProfile.validatedEmail &&
      myFiles.length === 0 ? (
        <Card pricing plain>
          <CardBody pricing>
            <div className={classes.icon}>
              <DescriptionIcon className={classes.iconRose} />
            </div>
            <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
              {t("no-shared-documents")}
            </h5>
          </CardBody>
        </Card>
      ) : (
        <GridContainer>
          {!loading &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail && (
              <FilterSortView
                handleClick={handleClick}
                showListView={showListView}
                pageSize={pageSize}
                handleShowListView={handleShowListView}
                sortBy={sortBy}
                handleSimple={handleSimple}
                handleSetSearchFilters={handleSetSearchFilters}
                filters={filters}
                handleSearchTitle={handleSearchTitle}
                showFilterSort={showFilterSort}
                handleShowFilterSort={handleShowFilterSort}
              />
            )}
          {!loading &&
            myFiles.length === 0 &&
            !isObjectEmpty(userProfile) &&
            !isObjectEmpty(filterParams) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail &&
            !showListView && (
              <Card pricing plain>
                <CardBody pricing>
                  <div className={classes.icon}></div>
                  <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    {t("sorry-we-couldnt-find-file")}
                  </h5>
                  <p className={classes.cardDescription}>
                    {t("try-a-new-search-or-clear-filters")}
                  </p>
                </CardBody>
              </Card>
            )}
          {!loading &&
            !showListView &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail &&
            myFiles.map((file) => {
              const queryParameters = new URLSearchParams({
                data_id: file.data_id,
                sender: file.sender,
                receiver: file.receiver,
                hash: file.hash,
              }).toString();
              return (
                <GridItem xs={6} sm={6} md={4} lg={3} key={file.data_id}>
                  <Link
                    to={`/admin/shared-documents/details?${queryParameters}`}
                  >
                    <Card pricing>
                      <CardBody pricing>
                        <div className={classes.icon}>
                          <div className={classes.iconRose}>
                            {getIcon(file.dataType)}
                          </div>
                        </div>
                        <h6
                          className={`${classes.cardTitle} ${classes.marginTop30}`}
                        >
                          {formatDate(file.createdAt)}
                        </h6>
                        <h5 className={`${classes.cardTitle} `}>
                          {file.title}
                        </h5>
                        <p className={classes.cardDescription}>
                          From: {lookupUsers[file.sender]}
                        </p>
                        <Button color="rose">{t("view")}</Button>
                      </CardBody>
                    </Card>
                  </Link>
                </GridItem>
              );
            })}
          {!loading &&
            showListView &&
            !isObjectEmpty(userProfile) &&
            userProfile.validatedDocuments &&
            userProfile.validatedEmail &&
            myFiles.length > 0 && (
              <>
                <GridItem xs={12}>
                  <Card>
                    <CardBody>
                      <ReactTable
                        data={prepareDataForTable(myFiles)}
                        sortable={true}
                        filterable={true}
                        columns={[
                          {
                            Header: t("title"),
                            accessor: "title",
                          },
                          {
                            Header: t("created-at"),
                            accessor: "createdAt",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("organization-number"),
                            accessor: "organizationNumber",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("invoice-number"),
                            accessor: "invoiceNumber",
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                          {
                            Header: t("document-type"),
                            accessor: "documentType",
                          },
                          {
                            Header: t("confirmed-status"),
                            accessor: "confirmedStatus",
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                            headerStyle: { textAlign: "right" },
                            Cell: (row) => (
                              <div style={{ textAlign: "right" }}>
                                {row.value}
                              </div>
                            ),
                          },
                        ]}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        defaultPageSize={10}
                        nextText={t("next")}
                        previousText={t("previous")}
                        loadingText={t("loading")}
                        noDataText={t("no-data")}
                        pageText={t("page")}
                        ofText={t("of")}
                        rowsText={t("rows")}
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </>
            )}
          <GridItem xs={12}>
            {!loading &&
              !isObjectEmpty(userProfile) &&
              isObjectEmpty(filterParams) &&
              userProfile.validatedDocuments &&
              userProfile.validatedEmail &&
              !showListView && (
                <Pagination
                  size="small"
                  count={totalPages}
                  color="secondary"
                  page={currentPage}
                  onChange={(event, page) => handleChangePage(page)}
                  className={classes2.root}
                />
              )}
          </GridItem>
        </GridContainer>
      )}
      {!isObjectEmpty(userProfile) &&
        (!userProfile.validatedDocuments || !userProfile.validatedEmail) && (
          <>
            <Card pricing plain>
              <CardBody pricing>
                <div className={classes.icon}>
                  {!userProfile.requestDocumentsValidation ? (
                    <SupervisedUserCircleIcon className={classes.iconPrimary} />
                  ) : (
                    <HourglassEmptyIcon className={classes.iconPrimary} />
                  )}
                </div>
                <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  {!userProfile.requestDocumentsValidation
                    ? t("in-order-to-proceed")
                    : t("waiting-for-document-validation")}
                </h5>
                {!userProfile.requestDocumentsValidation ? (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail && t("validate-email")}
                    {!userProfile.validatedEmail &&
                      (!userProfile.validatedDocuments ? " and " : ".")}
                    {!userProfile.validatedDocuments &&
                      t("request-a-document-validation")}
                  </p>
                ) : (
                  <p className={classes.cardDescription}>
                    {!userProfile.validatedEmail &&
                      t("please-validate-your-email")}
                  </p>
                )}
                <Link to="/admin/user-page" style={{ color: primaryColor[0] }}>
                  {t("go-to-user-profile")}
                </Link>
              </CardBody>
            </Card>
          </>
        )}
      <SnackBarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        variant={variant}
        message={snackbarMessage}
      />
    </>
  );
};

export default SharedDocuments;
