import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: "1px solid #ebedf2",
  },
  marginTop1: {
    marginTop: theme.spacing(1),
  },
}));

const ModalComponent = ({
  open,
  handleClose,
  dialogTitle,
  dialogContent,
  dialogActions,
  maxWidth = "sm",
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle className={classes.borderBottom} id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent className={classes.marginTop1}>
          {dialogContent}
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalComponent;
