import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import EditIcon from "@material-ui/icons/Edit";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import ClipLoader from "react-spinners/ClipLoader";
import SnackBarComponent from "components/SnackBarComponent.js";
import ApiService from "api/ApiService.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
      documentDetails: {},
      openSnackbar: false,
      variant: "",
      message: "",
      loading: false,
      emailAddress: "",
      bodyMessage: "",
    };
  }

  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }

  handleSignDocument = async () => {
    // Deletion parameters
    let documentData = {};
    try {
      documentData = await ApiService.getPackageDetails(this.props.queryParams);
    } catch (e) {
      console.error(e.message);
      this.setState({
        openSnackbar: true,
        variant: "error",
        message: e.message,
      });
    }

    const params = {
      parentId: documentData._id,
      parentType: "PACKAGE",
      access: documentData.access,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.addSignature(params, signal);
      this.setState({
        openSnackbar: true,
        variant: "success",
        message: "Document successfully signed",
      });
    } catch (e) {
      this.setState({
        openSnackbar: true,
        variant: "error",
        message: e.message,
      });
    }
  };

  handleGiveAccessSendEmail = async () => {
    this.setState({ loading: true });

    // Deletion parameters
    let documentData = {};
    try {
      documentData = await ApiService.getPackageDetails(this.props.queryParams);
    } catch (e) {
      console.error(e.message);
      this.setState({
        openSnackbar: true,
        variant: "error",
        message: e.message,
      });
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData;
    try {
      returnData = await ApiService.addAccessRights(
        {
          parentId: documentData._id,
          parentType: "PACKAGE",
          type: "SIGN",
          email: this.state.emailAddress,
          endOfLife: "-1",
        },
        signal
      );
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        openSnackbar: true,
        variant: "error",
        message: e.message,
      });
    }

    const params = {
      parentId: documentData._id,
      parentType: "PACKAGE",
      accessRightsId: returnData.accessRights.filter(
        (user) => user.email === this.state.emailAddress
      )[0]._id,
      frontendUrl: `${ApiService.serviceFrontendURL}/admin/documents/details`,
    };

    try {
      await ApiService.inviteAccessRights(params, signal);
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
        openSnackbar: true,
        variant: "error",
        message: e.message,
      });
    }
    this.setState({
      loading: false,
      openSnackbar: true,
      variant: "success",
      message: "Successfully send invitation",
      bodyMessage: "",
      emailAddress: "",
    });
  };

  // snackbar
  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackbar: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Sign the document (optional)</h4>
          </GridItem>
          <GridItem xs={12} sm={6} style={{ textAlign: "center" }}>
            <Button color="primary" onClick={this.handleSignDocument} round>
              <EditIcon className={classes.icons} /> Sign
            </Button>
          </GridItem>
        </GridContainer>
        <GridContainer
          justifyContent="center"
          style={{ padding: "20px 15px", marginTop: "20px" }}
        >
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Send document (optional)</h4>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Email"
              id="emailValue"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: this.state.emailAddress,
                onChange: (e) => {
                  this.setState({ emailAddress: e.target.value });
                },
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText="Message"
              id="messageValue"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                rows: 4,
                type: "text",
                value: this.state.bodyMessage,
                onChange: (e) => {
                  this.setState({ bodyMessage: e.target.value });
                },
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={6}>
            {this.state.loading ? (
              <Button round color="primary" disabled={this.state.loading}>
                <span className={classes.icons}>
                  <ClipLoader
                    size={18}
                    color={"#fff"}
                    loading={this.state.loading}
                  />
                </span>
                Sending
              </Button>
            ) : (
              <Button
                round
                color="primary"
                onClick={this.handleGiveAccessSendEmail}
                disabled={this.state.emailAddress.length < 3}
              >
                Send
              </Button>
            )}
          </GridItem>
        </GridContainer>

        <SnackBarComponent
          open={this.state.openSnackbar}
          handleClose={this.handleCloseSnackbar}
          variant={this.state.variant}
          message={this.state.message}
        />
      </>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step3);
