import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isObjectEmpty } from "views/Forms/WizardSteps/Step2";
import Info from "@material-ui/icons/Info";
import Gavel from "@material-ui/icons/Gavel";
import DescriptionIcon from "@material-ui/icons/Description";
import InfoIcon from "@material-ui/icons/Info";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import StorageIcon from "@material-ui/icons/Storage";
import HashTimeline from "./HashTimeline";
import LinkIcon from "@material-ui/icons/Link";
import CheckIcon from "@material-ui/icons/Check";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button";
import DocumentsTable from "components/DocumentsTable.js";
import SignaturesTable from "./SignaturesTable.js";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ApiService from "api/ApiService.js";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from "@material-ui/core/Tooltip";
import ModalComponent from "components/ModalComponent.js";
import UploadStepper from "components/UploadStepper.js";
import CustomNavPills from "./CustomNavPills";
import { Person } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput.js";
import SendIcon from "@material-ui/icons/Send";
import AccessRightsTable from "./AccessRightsTable";
import ClipLoader from "react-spinners/ClipLoader";
import SnackBarComponent from "components/SnackBarComponent.js";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PDFViewer from "./PDFViewer";
import QRCode from "react-qr-code";
import AddMetadataInputs from "./AddMetadataInputs";
import moment from "moment";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { Link } from "react-router-dom";
import {
  roseColor,
  lightBlue,
  whiteColor,
  grayColor,
  successColor,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: grayColor[2],
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: grayColor[0],
  },
  cardIconTitle: {
    marginTop: "35px",
  },
  backdrop: {
    zIndex: 100,
    color: whiteColor,
  },
};

const useStyles = makeStyles(styles);

const ContainerDetailsPage = (props) => {
  const classes = useStyles();

  const containerId = props.match.params.id;

  const [state, setState] = React.useState(ApiService.initState);

  const [documentContainer, setDocumentContainer] = React.useState({});
  const [documentContainerQrCode, setDocumentContainerQrCode] = React.useState(
    null
  );
  const [showStepper, setShowStepper] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [variant, setVariant] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [emailAddress, setEmailAddress] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [deleting, setDeleting] = React.useState(false);
  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = React.useState(
    false
  );
  const [showDetails, setShowDetails] = React.useState(false);
  const [documentToView, setDocumentToView] = React.useState({});
  const [documentType, setDocumentType] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);

  const [openVerifyHashModal, setOpenVerifyHashModal] = React.useState(false);
  const [verificationData, setVerificationData] = React.useState([]);
  const [openBCModal, setOpenBCModal] = React.useState(false);

  const [openSignContainerModal, setOpenSignContainerModal] = React.useState(
    false
  );
  const [
    openDeleteSignatureModal,
    setOpenDeleteSignatureModal,
  ] = React.useState(false);
  const [deleteParams, setDeleteParams] = React.useState({});
  const [
    openVerifySignatureModal,
    setOpenVerifySignatureModal,
  ] = React.useState(false);
  const [
    signatureVerificationData,
    setSignatureVerificationData,
  ] = React.useState([]);
  const [pdfFile, setPdfFile] = React.useState({ data: new Uint8Array(0) });
  const [pdfReport, setPdfReport] = React.useState({ data: new Uint8Array(0) });
  const [lookupUsers, setLookupUsers] = React.useState({});
  const [userProfile, setUserProfile] = React.useState({});

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const getQueryParams = React.useCallback(
    async (signal = undefined) => {
      const queryParams = new URLSearchParams(props.location.search);
      const params = {
        userId: queryParams.get("userId"),
      };
      const returnData = await ApiService.loginRequired(signal, false);
      if (returnData.superuser && params.userId) {
        const userProfileData = await ApiService.readUser(params, signal);

        params.userId = userProfileData._id;
      } else {
        params.userId = returnData._id;
      }
      params.superuser = returnData.superuser;
      return params;
    },
    [props.location.search]
  );

  const handleRefresh = React.useCallback(
    async (returnData = undefined, signal = undefined) => {
      let documentContainerData;
      let userId;

      if (!returnData) {
        const url = new URL(`${ApiService.serviceFrontendURL}/auth/login-page`);
        url.search = new URLSearchParams({
          redirectPath: `/admin/containers/${containerId}`,
        });

        try {
          const queryParams = await getQueryParams(signal);
          userId = queryParams.userId;
          const queryDocumentContainerParams = {
            userId,
            documentContainerId: containerId,
          };

          documentContainerData = await ApiService.getDocumentContainer(
            queryDocumentContainerParams,
            signal
          );

          const qrCode = await ApiService.getDocumentContainerQrCode(
            {
              userId,
              frontendUrl: url.toString(),
              documentContainerId: containerId,
            },
            signal
          );
          setDocumentContainerQrCode(qrCode.qrCode);
        } catch (e) {
          console.error(e);
          props.history.push("/auth/error-page");
        }
      } else {
        documentContainerData = returnData;
      }
      setDocumentContainer(documentContainerData);
    },
    [containerId, props.history, getQueryParams]
  );

  React.useEffect(() => {
    let isMounted = true;

    if (
      isMounted &&
      !isObjectEmpty(documentToView) &&
      !isObjectEmpty(documentContainer)
    ) {
      const refreshedDocumentToView = documentContainer.packages.filter(
        (doc) => doc._id === documentToView._id
      );
      if (refreshedDocumentToView.length > 0) {
        setDocumentToView(refreshedDocumentToView[0]);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [documentContainer, documentToView]);

  React.useEffect(() => {
    if (!isObjectEmpty(documentContainer) && !showDetails) {
      setState(ApiService.initState);
      documentContainer.metadata.map((data) => {
        setState((state) => ({
          ...state,
          [data.name]: { ...state[data.name], value: data.metadata },
        }));
        return null;
      });
    }
    if (!isObjectEmpty(documentToView) && showDetails) {
      setState(ApiService.initState);
      documentToView.metadata
        .filter((data) => data.name !== "Document Type")
        .map((data) => {
          setState((state) => ({
            ...state,
            [data.name]: { ...state[data.name], value: data.metadata },
          }));
          return null;
        });
    }
  }, [documentContainer, showDetails, documentToView]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted && isObjectEmpty(lookupUsers)) {
      const getUsers = async () => {
        const responseData = await ApiService.getAllUsers({});
        const users = {};
        for (const entry of responseData) {
          users[entry.address] = entry.user.email;
        }
        setLookupUsers(users);
      };

      getUsers();
    }
    return () => {
      isMounted = false;
    };
  }, [lookupUsers]);

  const handleSetAdditionalInfoState = () => {
    setState(ApiService.initState);
    if (!isObjectEmpty(documentContainer)) {
      documentContainer.metadata.map((data) => {
        setState((state) => ({
          ...state,
          [data.name]: { ...state[data.name], value: data.metadata },
        }));
        return null;
      });
    }
  };

  const handleSetAdditionalInfoStateDoc = () => {
    setState(ApiService.initState);
    if (!isObjectEmpty(documentToView)) {
      documentToView.metadata
        .filter((data) => data.name !== "Document Type")
        .map((data) => {
          setState((state) => ({
            ...state,
            [data.name]: { ...state[data.name], value: data.metadata },
          }));
          return null;
        });
    }
  };

  const handleOpenQrModal = () => {
    setOpenModal(true);
  };

  const handleCloseOrModal = () => {
    setOpenModal(false);
  };

  // document steper
  const handleShowStepper = () => {
    setShowStepper(true);
  };

  const handleFinishButton = async () => {
    setShowStepper(false);

    try {
      const queryParams = await getQueryParams();
      const userId = queryParams.userId;
      const queryDocumentContainerParams = {
        userId,
        documentContainerId: containerId,
      };

      const documentContainerData = await ApiService.getDocumentContainer(
        queryDocumentContainerParams
      );

      setDocumentContainer(documentContainerData);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  // delete document
  const handleOpenDeleteDocumentModal = () => {
    setOpenDeleteDocumentModal(true);
  };
  const handleCloseDeleteDocumentModal = () => {
    setOpenDeleteDocumentModal(false);
  };

  // metadata
  const handleSetState = (value, key) => {
    if (key === "Invoice date") {
      setState({
        ...state,
        [key]: {
          ...state[key],
          value: value !== null ? moment(value).toISOString() : "",
        },
      });
    } else {
      setState({ ...state, [key]: { ...state[key], value: value } });
    }
  };

  // select add metadata
  const handleAddData = async (parentType) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const docDetails =
      parentType === "CONTAINER" ? documentContainer : documentToView;
    for (const property in state) {
      const found = docDetails.metadata.filter(
        (data) => data.name === property
      );
      if (
        (found.length > 0 &&
          state[property].value.length > 0 &&
          found[0].metadata !== state[property].value) ||
        (found.length === 0 && state[property].value.length > 0)
      ) {
        try {
          await ApiService.addMetadata(
            {
              parentId: docDetails._id,
              parentType: parentType,
              name: property,
              type: state[property].type,
              metadata:
                state[property].type === ApiService.metadataTypes.json
                  ? JSON.parse(state[property].value)
                  : state[property].value,
            },
            signal
          );
          setOpenSnackbar(true);
          setVariant("success");
          setSnackbarMessage("Successfully added data");
          handleRefresh();
        } catch (e) {
          console.error(e);
          setOpenSnackbar(true);
          setVariant("warning");
          setSnackbarMessage(e.message);
        }
      }
      if (
        found.length > 0 &&
        state[property].value.length === 0 &&
        found[0].metadata !== state[property].value
      ) {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const params = {
          parentId: docDetails._id,
          parentType: parentType,
          metadataId: found[0]._id,
        };
        try {
          await ApiService.deleteMetadata(params, signal);
          handleRefresh();
        } catch (e) {
          console.error(e);
          setOpenSnackbar(true);
          setVariant("warning");
          setSnackbarMessage(e.message);
        }
      }
    }
  };

  const handleDeleteContainer = async () => {
    setOpenDeleteModal(false);

    setDeleting(true);
    try {
      await ApiService.deleteDocumentContainer({
        documentContainerId: documentContainer._id,
      });
      setDeleting(false);
      props.history.push("/admin/containers");
    } catch (e) {
      setDeleting(false);
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(e.message);
    }
  };

  const handleGiveAccessSendEmail = async () => {
    setLoading(true);
    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    let returnData;
    try {
      returnData = await ApiService.addAccessRights(
        {
          parentId: documentContainer._id,
          parentType: "CONTAINER",
          type: "SIGN",
          email: emailAddress,
          endOfLife: "-1",
        },
        signal
      );
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setLoading(false);
      setVariant("error");
      setSnackbarMessage(e.message);
      return;
    }

    const params = {
      parentId: documentContainer._id,
      parentType: "CONTAINER",
      message,
      accessRightsId: returnData.accessRights.filter(
        (user) => user.email === emailAddress
      )[0]._id,
      frontendUrl: getFrontendUrl(),
    };

    try {
      await ApiService.inviteAccessRights(params, signal);
      handleRefresh();
    } catch (e) {
      console.error(e);
      setLoading(false);
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(e.message);
    }
    setLoading(false);
    setEmailAddress("");
    setOpenSnackbar(true);
    setMessage("");
    setVariant("success");
    setSnackbarMessage("Successfully send invitation");
  };

  const handleDeleteAccess = async (e, parentId, id, parentType) => {
    // Deletion parameters
    const params = {
      parentId,
      parentType,
      accessRightsId: id,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.deleteAccessRights(params, signal);
      setOpenSnackbar(true);
      setVariant("success");
      setSnackbarMessage("Successfully revoked access right");
    } catch (e) {
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
    await handleRefresh();
  };

  // download doc
  const handleDownloadFile = () => {
    const handleDownloadFile = async (userId, parameters, signal) => {
      const params = {
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      a.click();
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      const packageParams = {
        data_id: documentToView.data_id,
        hash: documentToView.hash,
        sender: documentToView.sender,
        receiver: documentToView.receiver,
      };
      try {
        responseData = await ApiService.prepareDownloadFile(
          packageParams,
          signal
        );
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(e.message);
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handleDownloadFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(e.message);
      }
    };

    handlePrepareFileDownload();
  };

  // document preview
  const handleLoadPreviewFile = (docs) => {
    const handleDownloadFile = async (userId, parameters, signal) => {
      const params = {
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadFile(params, signal);
      const fileBuffer = await responseData.arrayBuffer();
      const uint8View = new Uint8Array(fileBuffer);
      setPdfFile({ data: uint8View });
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      const packageParams = {
        data_id: docs.data_id,
        hash: docs.hash,
        sender: docs.sender,
        receiver: docs.receiver,
      };
      try {
        responseData = await ApiService.prepareDownloadFile(
          packageParams,
          signal
        );
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(e.message);
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handleDownloadFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(e.message);
      }
    };

    handlePrepareFileDownload();
  };

  const handleShowDetails = (e, docs) => {
    setShowDetails(!showDetails);
    showDetails && setPdfFile({ data: new Uint8Array(0) });
    setDocumentToView(docs);
    const metadata =
      docs.metadata.length > 0 &&
      docs.metadata.filter((data) => data.name === "Document Type");
    if (metadata.length > 0) {
      setDocumentType(metadata[0].metadata);
    } else {
      setDocumentType("Other");
    }
    !showDetails &&
      docs.dataType === "application/pdf" &&
      handleLoadPreviewFile(docs);
  };

  // delete file
  const handleDeleteFile = async () => {
    setOpenDeleteDocumentModal(false);

    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.deleteFile(
        {
          data_id: documentToView.data_id,
          hash: documentToView.hash,
          sender: documentToView.sender,
          receiver: documentToView.receiver,
        },
        signal
      );
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(e.message);
    }
    const jobId = responseData.jobId;
    try {
      const jobParams = { jobId };
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        if (responseData.status === ApiService.jobStatus.failed) {
          setOpenSnackbar(true);
          setVariant("error");
          setSnackbarMessage("Error deleting file");
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          handleRefresh();
        }
      }
      if (signal.aborted) {
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage("Operation aborted");
      }
    } catch (e) {
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage("Error deleting file");
    }
  };

  const handleCancelUpload = () => {
    setShowStepper(false);
  };

  //  file hash
  const handleOpenVerifyHashModal = async () => {
    setOpenVerifyHashModal(true);
    try {
      const packageVerificationData = await ApiService.getPackageSignature({
        data_id: documentToView.data_id,
        hash: documentToView.hash,
        sender: documentToView.sender,
        receiver: documentToView.receiver,
      });

      setVerificationData(packageVerificationData.verificationSteps);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
  };

  const handleCloseVerifyHashModal = () => {
    setOpenVerifyHashModal(false);
  };

  const handleOpenBCModal = () => {
    setOpenBCModal(true);
  };

  const handleCloseBCModal = () => {
    setOpenBCModal(false);
  };

  // Sign container
  const handleOpenSignModal = () => {
    setOpenSignContainerModal(true);
  };

  const handleCloseSignModal = () => {
    setOpenSignContainerModal(false);
  };

  const handleSignContainer = async () => {
    setOpenSignContainerModal(false);
    // Deletion parameters
    const params = {
      parentId: documentContainer._id,
      parentType: "CONTAINER",
      access: documentContainer.access,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.addSignature(params, signal);
      setOpenSnackbar(true);
      setVariant("success");
      setSnackbarMessage("Container successfully signed");

      await handleRefresh();
    } catch (e) {
      setOpenSnackbar(true);
      setVariant("error");
      setSnackbarMessage(e.message);
    }
  };

  // Signatures tab
  const handleOpenContainerSignatureModal = async (
    signatureId,
    parentId,
    parentType,
    metadataHash,
    metadata
  ) => {
    setOpenVerifySignatureModal(true);

    try {
      const response = await ApiService.verifySignature({
        signatureId,
        parentId,
        parentType,
      });
      setSignatureVerificationData(response.verificationSteps);
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
  };

  const handleCloseVerifySignatureModal = () => {
    setOpenVerifySignatureModal(false);
  };

  const handleOpenDeleteContainerSignatureModal = (
    signatureId,
    parentId,
    parentType
  ) => {
    setDeleteParams({ signatureId, parentId, parentType });
    setOpenDeleteSignatureModal(true);
  };

  const handleCloseDeleteContainerSignatureModal = () => {
    setOpenDeleteSignatureModal(false);
  };

  const handleDeleteContainerSignature = async () => {
    setOpenDeleteSignatureModal(false);
    try {
      await ApiService.deleteSignature(deleteParams);
      await handleRefresh();
    } catch (e) {
      console.error(e);
      setOpenSnackbar(true);
      setVariant("warning");
      setSnackbarMessage(e.message);
    }
  };

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/auth/login-page`);
    url.search = new URLSearchParams({
      redirectPath: `/admin/containers/${documentContainer._id}`,
    });

    return url.toString();
  };

  const handleDownloadReport = () => {
    const handlePreviewFile = async (userId, parameters, signal, type) => {
      const reportParams = {
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(
        reportParams,
        signal
      );

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(responseData);
      a.download = parameters.details.fileName;
      a.click();
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        parentId: documentContainer._id,
        parentType: "CONTAINER",
        frontendUrl: getFrontendUrl(),
        creatorName: "Abendum MVP",
        documentTitle: "Abendum MVP Report",
        reportTitle: "Report",
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(
          reportParams,
          signal
        );
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(e.message);
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(e.message);
      }
    };
    handlePrepareFileDownload();
  };

  const handleLoadPreviewReport = (type) => {
    const handlePreviewFile = async (userId, parameters, signal, type) => {
      const reportParams = {
        jobId: parameters.jobId,
      };
      const responseData = await ApiService.downloadReport(
        reportParams,
        signal
      );

      const fileBuffer = await responseData.arrayBuffer();
      const uint8View = new Uint8Array(fileBuffer);
      setPdfReport({ data: uint8View });
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        parentId: documentContainer._id,
        parentType: "CONTAINER",
        frontendUrl: getFrontendUrl(),
        creatorName: "Abendum MVP",
        documentTitle: "Abendum MVP Report",
        reportTitle: "Report",
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(
          reportParams,
          signal
        );
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(e.message);
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(
            jobParams,
            signal
          );
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package");
        setOpenSnackbar(true);
        setVariant("error");
        setSnackbarMessage(e.message);
      }
    };
    if (type === "report") {
      handlePrepareFileDownload();
    }
  };

  const documentDetailsCode = (
    <>
      <GridItem
        xs={12}
        sm={documentToView.dataType === "application/pdf" ? 12 : 4}
      >
        <Card>
          <CardHeader color="lightBlue" icon>
            <CardIcon color="lightBlue">
              <InfoIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Document info</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justifyContent="space-between">
              <GridItem xs={12} sm={4}>
                Name
              </GridItem>
              <GridItem xs={12} sm={8} style={{ textAlign: "right" }}>
                {documentToView.title}
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="space-between">
              <GridItem xs={12} sm={4}>
                Owner
              </GridItem>
              <GridItem xs={12} sm={8} style={{ textAlign: "right" }}>
                {lookupUsers[documentToView.sender]}
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="space-between">
              <GridItem xs={12} sm={4}>
                Access
              </GridItem>
              <GridItem xs={12} sm={8} style={{ textAlign: "right" }}>
                {documentToView.access}
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="space-between">
              <GridItem xs={12} sm={4}>
                Type
              </GridItem>
              <GridItem xs={12} sm={8} style={{ textAlign: "right" }}>
                {documentType}
              </GridItem>
            </GridContainer>

            <hr className={classes.footerLine} />
            <div style={{ display: "inline" }}>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  className={classes.margin}
                  style={{ color: grayColor[20] }}
                  onClick={handleOpenDeleteDocumentModal}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download document">
                <IconButton
                  aria-label="download"
                  className={classes.margin}
                  style={{ color: lightBlue[0] }}
                  onClick={handleDownloadFile}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        xs={12}
        sm={documentToView.dataType === "application/pdf" ? 12 : 8}
      >
        <Card>
          <CardHeader color="lightBlue" icon>
            <CardIcon color="lightBlue">
              <LinkIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Blockchain info</h4>
          </CardHeader>
          <CardBody>
            <GridContainer justifyContent="space-between">
              <GridItem xs={12} sm={4}>
                TXID
              </GridItem>
              <GridItem xs={12} sm={8} style={{ textAlign: "right" }}>
                {documentToView.transactionId}
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="space-between">
              <GridItem xs={12} sm={4}>
                Hash
              </GridItem>
              <GridItem xs={12} sm={8} style={{ textAlign: "right" }}>
                {documentToView.hash}
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="space-between">
              <GridItem xs={12} sm={4}>
                Status
              </GridItem>
              <GridItem xs={12} sm={8} style={{ textAlign: "right" }}>
                {documentToView.transactionStatus}
              </GridItem>
            </GridContainer>

            <hr className={classes.footerLine} />
            <div style={{ display: "inline" }}>
              <Tooltip title="View on chain">
                <IconButton
                  aria-label="view-on-chain"
                  className={classes.margin}
                  style={{ color: roseColor[0] }}
                  onClick={handleOpenBCModal}
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>

              <div style={{ float: "right" }}>
                <Button
                  color="primary"
                  className={classes.marginRight}
                  onClick={handleOpenVerifyHashModal}
                >
                  <CheckIcon className={classes.icons} /> VERIFY HASH
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="lightBlue" icon>
            <CardIcon color="lightBlue">
              <StorageIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Additional info</h4>
          </CardHeader>
          <CardBody>
            <AddMetadataInputs
              currentState={state}
              handleSetState={handleSetState}
              handleAddData={() => handleAddData("PACKAGE")}
            />
          </CardBody>
        </Card>
      </GridItem>
    </>
  );

  const Loading = (loading) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "left",
          right: "50%",
        }}
      >
        <ClipLoader size={40} color={"#CFAF4E"} loading={loading} />
      </div>
    );
  };

  const previewReport = {
    tabButton: "Report",
    tabIcon: AssignmentIcon,
    tabContent: (
      <GridContainer xs={12}>
        <GridItem xs={12}>
          {pdfReport.data.length > 0 && <PDFViewer data={pdfReport} />}
        </GridItem>
      </GridContainer>
    ),
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        const userProfile = await ApiService.loginRequired(signal, false);
        setUserProfile(userProfile);
        await handleRefresh(undefined, signal);
      } catch (e) {
        console.error(e);
        setOpenSnackbar(true);
        setVariant("warning");
        setSnackbarMessage(e.message);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [handleRefresh]);

  return (
    <>
      {isObjectEmpty(documentContainer) && <Loading loading={true} />}
      {!isObjectEmpty(documentContainer) &&
        !isObjectEmpty(userProfile) &&
        userProfile.validatedDocuments &&
        userProfile.validatedEmail && (
          <>
            <Backdrop className={classes.backdrop} open={deleting}>
              <h2>Deleting container...</h2>
              <CircularProgress color="inherit" />
            </Backdrop>
            <GridContainer justifyContent="center">
              <GridItem xs={12}>
                <NavPills
                  color="darkPrimary"
                  alignCenter
                  tabs={[
                    {
                      tabButton: "Info",
                      tabIcon: Info,
                      tabContent: (
                        <GridContainer xs={12}>
                          <GridItem xs={12} lg={4}>
                            <Card>
                              <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                  <InfoIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  Container info
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <GridContainer justifyContent="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Name
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentContainer.title}
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justifyContent="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Owner
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {lookupUsers[documentContainer.sender]}
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justifyContent="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Access
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentContainer.access}
                                  </GridItem>
                                </GridContainer>
                                <GridContainer justifyContent="space-between">
                                  <GridItem xs={12} sm={4}>
                                    Documents
                                  </GridItem>
                                  <GridItem
                                    xs={12}
                                    sm={8}
                                    style={{ textAlign: "right" }}
                                  >
                                    {documentContainer.packages &&
                                      documentContainer.packages.filter(
                                        (doc) => doc.status !== "DELETED"
                                      ).length}
                                  </GridItem>
                                </GridContainer>

                                <hr className={classes.footerLine} />
                                <div style={{ display: "inline" }}>
                                  <Tooltip title="Delete">
                                    <IconButton
                                      aria-label="delete"
                                      className={classes.margin}
                                      style={{ color: grayColor[20] }}
                                      onClick={handleOpenDeleteModal}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Download report">
                                    <IconButton
                                      aria-label="download"
                                      className={classes.margin}
                                      style={{ color: roseColor[0] }}
                                      onClick={handleDownloadReport}
                                    >
                                      <AssignmentIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Open QR code">
                                    <IconButton
                                      aria-label="open-qrcode"
                                      className={classes.margin}
                                      style={{ color: successColor[0] }}
                                      onClick={handleOpenQrModal}
                                    >
                                      <i className="fas fa-qrcode"></i>
                                    </IconButton>
                                  </Tooltip>

                                  <div style={{ float: "right" }}>
                                    <Button
                                      color="primary"
                                      className={classes.marginRight}
                                      onClick={handleOpenSignModal}
                                    >
                                      <EditIcon className={classes.icons} />{" "}
                                      Sign
                                    </Button>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} lg={8}>
                            <Card>
                              <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                  <LockOpenIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  Access rights
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <CustomNavPills
                                  color="#DBB686"
                                  tabButton1="Receivers"
                                  tabIcon1={Person}
                                  tabContent1={
                                    documentContainer.accessRights &&
                                    documentContainer.accessRights.length >
                                      0 ? (
                                      <AccessRightsTable
                                        accessRights={
                                          documentContainer.accessRights
                                        }
                                        handleDeleteAccess={handleDeleteAccess}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          marginTop: "4rem",
                                        }}
                                      >
                                        No receivers yet
                                      </div>
                                    )
                                  }
                                  tabButton2="Send"
                                  tabIcon2={SendIcon}
                                  tabContent2={
                                    <>
                                      <GridContainer xs={12}>
                                        <GridItem xs={2}></GridItem>
                                        <GridItem xs={12}>
                                          <CustomInput
                                            labelText="Email"
                                            id="emailValue"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "text",
                                              value: emailAddress,
                                              onChange: (e) => {
                                                setEmailAddress(e.target.value);
                                              },
                                            }}
                                          />
                                        </GridItem>
                                        <GridItem xs={12}>
                                          <CustomInput
                                            labelText="Message"
                                            id="messageValue"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              multiline: true,
                                              rows: 4,
                                              type: "text",
                                              value: message,
                                              onChange: (e) => {
                                                setMessage(e.target.value);
                                              },
                                            }}
                                          />
                                        </GridItem>
                                        <GridItem xs={10}>
                                          {loading ? (
                                            <Button
                                              round
                                              color="primary"
                                              disabled={loading}
                                            >
                                              <span className={classes.icons}>
                                                <ClipLoader
                                                  size={18}
                                                  color={"#fff"}
                                                  loading={loading}
                                                />
                                              </span>
                                              Sending
                                            </Button>
                                          ) : (
                                            <Button
                                              round
                                              color="primary"
                                              onClick={
                                                handleGiveAccessSendEmail
                                              }
                                              disabled={emailAddress.length < 3}
                                            >
                                              Send
                                            </Button>
                                          )}
                                        </GridItem>
                                      </GridContainer>
                                    </>
                                  }
                                />
                              </CardBody>
                            </Card>
                          </GridItem>
                          <GridItem xs={12}>
                            <Card>
                              <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                  <StorageIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  Additional info
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <AddMetadataInputs
                                  currentState={state}
                                  handleSetState={handleSetState}
                                  handleAddData={() =>
                                    handleAddData("CONTAINER")
                                  }
                                />
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Documents",
                      tabIcon: DescriptionIcon,
                      tabContent: (
                        <GridContainer xs={12}>
                          <GridItem xs={12}>
                            {showStepper ? (
                              <UploadStepper
                                handleFinishButton={handleFinishButton}
                                containerId={containerId}
                                handleCancelUpload={handleCancelUpload}
                              />
                            ) : (
                              <GridContainer xs={12}>
                                <GridItem xs={12}>
                                  <Card>
                                    <CardHeader
                                      color="primary"
                                      icon
                                      style={{ display: "inline-flex" }}
                                    >
                                      <CardIcon color="primary">
                                        <DescriptionIcon />
                                      </CardIcon>
                                      <h4 className={classes.cardIconTitle}>
                                        Documents
                                      </h4>
                                      <Button
                                        color="rose"
                                        size="lg"
                                        simple
                                        style={{ marginLeft: "auto" }}
                                        onClick={handleShowStepper}
                                      >
                                        <i
                                          className={
                                            classes.socialButtonsIcons +
                                            " " +
                                            classes.marginRight +
                                            " fas fa-cloud-upload-alt"
                                          }
                                        />{" "}
                                        Upload document
                                      </Button>
                                    </CardHeader>
                                    <CardBody>
                                      <DocumentsTable
                                        documents={documentContainer.packages}
                                        handleShowDetails={handleShowDetails}
                                      />
                                    </CardBody>
                                  </Card>
                                </GridItem>
                                {!isObjectEmpty(documentToView) &&
                                  showDetails &&
                                  documentToView.status !== "DELETED" && (
                                    <>
                                      {documentToView.dataType ===
                                      "application/pdf" ? (
                                        <>
                                          <GridItem xs={12} sm={6}>
                                            <GridContainer>
                                              {documentDetailsCode}
                                            </GridContainer>
                                          </GridItem>
                                          <GridItem xs={12} sm={6}>
                                            {pdfFile.data.length > 0 && (
                                              <PDFViewer data={pdfFile} />
                                            )}
                                          </GridItem>
                                        </>
                                      ) : (
                                        documentDetailsCode
                                      )}
                                    </>
                                  )}
                              </GridContainer>
                            )}
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "Signatures",
                      tabIcon: Gavel,
                      tabContent: (
                        <GridContainer xs={12}>
                          <GridItem xs={12}>
                            <Card>
                              <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                  <DescriptionIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                  Signatures
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <SignaturesTable
                                  data={documentContainer.signatures}
                                  currentHash={documentContainer.hash}
                                  handleVerifyHash={
                                    handleOpenContainerSignatureModal
                                  }
                                  handleDeleteSignature={
                                    handleOpenDeleteContainerSignatureModal
                                  }
                                />
                              </CardBody>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    previewReport,
                  ]}
                  action={handleLoadPreviewReport}
                  handle={handleSetAdditionalInfoState}
                  handleDoc={handleSetAdditionalInfoStateDoc}
                />
              </GridItem>
            </GridContainer>

            <ModalComponent
              open={openModal}
              handleClose={handleCloseOrModal}
              dialogTitle={"Container QR code"}
              maxWidth="xs"
              dialogContent={
                <div style={{ textAlign: "center" }}>
                  <img
                    height="200px"
                    src={documentContainerQrCode}
                    alt="Qr-code"
                  />
                </div>
              }
            />
            <ModalComponent
              open={openDeleteModal}
              handleClose={handleCloseDeleteModal}
              dialogTitle={"Delete container"}
              maxWidth="xs"
              dialogContent={
                <div>{"Are you sure you want to delete this container?"}</div>
              }
              dialogActions={
                <React.Fragment>
                  <Button simple onClick={handleCloseDeleteModal} color="rose">
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteContainer}
                  >
                    Yes
                  </Button>
                </React.Fragment>
              }
            />

            <ModalComponent
              open={openDeleteDocumentModal}
              handleClose={handleCloseDeleteDocumentModal}
              dialogTitle={"Delete document"}
              maxWidth="xs"
              dialogContent={
                <div>{"Are you sure you want to delete this document?"}</div>
              }
              dialogActions={
                <React.Fragment>
                  <Button
                    simple
                    onClick={handleCloseDeleteDocumentModal}
                    color="rose"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteFile}
                  >
                    Yes
                  </Button>
                </React.Fragment>
              }
            />

            <SnackBarComponent
              open={openSnackbar}
              handleClose={handleCloseSnackbar}
              variant={variant}
              message={snackbarMessage}
            />

            <ModalComponent
              open={openVerifyHashModal}
              handleClose={handleCloseVerifyHashModal}
              dialogTitle={"Document hash signature verification"}
              maxWidth="md"
              dialogContent={
                <>
                  {verificationData.length > 0 && (
                    <HashTimeline steps={verificationData} />
                  )}
                </>
              }
            />

            <ModalComponent
              open={openSignContainerModal}
              handleClose={handleCloseSignModal}
              dialogTitle={"Sign container"}
              maxWidth="xs"
              dialogContent={
                <div>{"Are you sure you want to sign this container?"}</div>
              }
              dialogActions={
                <React.Fragment>
                  <Button simple onClick={handleCloseSignModal} color="rose">
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSignContainer}
                  >
                    Sign
                  </Button>
                </React.Fragment>
              }
            />

            <ModalComponent
              open={openVerifySignatureModal}
              handleClose={handleCloseVerifySignatureModal}
              dialogTitle={"Container hash signature verification"}
              maxWidth="md"
              dialogContent={
                <>
                  {signatureVerificationData.length > 0 && (
                    <HashTimeline steps={signatureVerificationData} />
                  )}
                </>
              }
            />

            <ModalComponent
              open={openDeleteSignatureModal}
              handleClose={handleCloseDeleteContainerSignatureModal}
              dialogTitle={"Delete Signature"}
              maxWidth="xs"
              dialogContent={
                <div>{"Are you sure you want to delete this signature?"}</div>
              }
              dialogActions={
                <React.Fragment>
                  <Button
                    simple
                    onClick={handleCloseDeleteContainerSignatureModal}
                    color="rose"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteContainerSignature}
                  >
                    Yes
                  </Button>
                </React.Fragment>
              }
            />

            <ModalComponent
              open={openBCModal}
              handleClose={handleCloseBCModal}
              dialogTitle={"View on chain"}
              maxWidth="xs"
              dialogContent={
                <div>
                  {documentToView.transactionStatus === "PREPARED" ? (
                    <p>
                      Transaction is presigned but not yet written to blockchain
                    </p>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <QRCode
                        size={200}
                        value={`https://whatsonchain.com/tx/${documentToView.transactionId}`}
                      />
                      <div style={{ textAlign: "center", marginTop: "2rem" }}>
                        <a
                          href={`https://whatsonchain.com/tx/${documentToView.transactionId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              }
            />
          </>
        )}
      {!isObjectEmpty(userProfile) &&
        (!userProfile.validatedDocuments || !userProfile.validatedEmail) && (
          <>
            <Card pricing plain>
              <CardBody pricing>
                <div className={classes.icon}>
                  <SupervisedUserCircleIcon className={classes.iconPrimary} />
                </div>
                <h5 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                  {"In order to proceed"}
                </h5>
                <p className={classes.cardDescription}>
                  {!userProfile.validatedEmail && "validate email"}
                  {!userProfile.validatedEmail &&
                    (!userProfile.validatedDocuments ? " and " : ".")}
                  {!userProfile.validatedDocuments &&
                    "request a document validation"}
                </p>
                <Link to="/admin/user-page" style={{ color: primaryColor[0] }}>
                  Go to user profile
                </Link>
              </CardBody>
            </Card>
          </>
        )}
    </>
  );
};

export default ContainerDetailsPage;
