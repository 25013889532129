import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import ApiService from "api/ApiService.js";
import isEmail from "validator/lib/isEmail";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  roseColor,
  primaryColor,
  lightBlue,
} from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const { t } = useTranslation();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [requireToken, setRequireToken] = React.useState(false);
  const [token2FA, setToken2FA] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [clickAction, setClickAction] = React.useState("");

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  React.useEffect(() => {
    ApiService.removeSessionToken();
  }, []);

  const showErrorMessage = (messageContent) => {
    setClickAction("");
    if (!requireToken) {
      if (messageContent === "Invalid 2FA token!") {
        setRequireToken(true);
        setMessage(t("2fa-qr-code-secret"));
        return setToken2FA("");
      }
    } else {
      setToken2FA("");
    }
    if (messageContent === "User does not exist!") {
      setClickAction("invalid user");
      return setMessage(t("user-doesnt-exist"));
    }
    if (messageContent === "Invalid password!") {
      setClickAction("invalid password");
      return setMessage(t("incorrect-password"));
    }
    setMessage(messageContent);
  };

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      redirectPath: queryParams.get("redirectPath"),
    };
    return params;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validEmail = isEmail(email);
    if (!validEmail) {
      setEmailError("Invalid email address");
      showErrorMessage(
        `${t("invalid-information-provided")} ${t("invalid-email")}`
      );
      return;
    }
    try {
      await ApiService.loginUser({ email, password, token2FA });
      const queryParams = getQueryParams();
      if (queryParams.redirectPath) {
        props.history.push(queryParams.redirectPath);
      } else {
        props.history.push("/");
      }
    } catch (e) {
      showErrorMessage(e.message);
    }
  };

  const handleCloseMessage = () => {
    setMessage("");
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={5} lg={4}>
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="white"
              >
                <h4
                  className={classes.cardTitle}
                  style={{ color: lightBlue[1], marginTop: "15px" }}
                >
                  {t("welcome")}
                </h4>
                {!ApiService.showRegisterPage && (
                  <h6>{t("only-for-registered-customers")}</h6>
                )}
              </CardHeader>
              <CardBody>
                {message.length > 0 && (
                  <SnackbarContent
                    message={message}
                    clickAction={clickAction}
                    color="warning"
                    click={handleCloseMessage}
                  />
                )}
                <CustomInput
                  labelText={t("email")}
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "email",
                    required: true,
                    autoFocus: true,
                    onChange: (e) => {
                      setMessage("");
                      setEmail(e.target.value);
                    },
                    value: email,
                  }}
                  error={emailError.length > 0}
                  helperText={emailError}
                />
                <CustomInput
                  labelText={t("password")}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    required: true,
                    onChange: (e) => {
                      setMessage("");
                      setPassword(e.target.value);
                    },
                    value: password,
                  }}
                />
                {requireToken && (
                  <CustomInput
                    labelText={t("2fa-token")}
                    id="token2FA"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                      required: true,
                      onChange: (e) => {
                        setMessage("");

                        setToken2FA(e.target.value);
                      },
                      value: token2FA,
                    }}
                  />
                )}
                <Link
                  to="/auth/reset-password-email"
                  style={{ color: primaryColor[0] }}
                >
                  {t("forgot-password")}
                </Link>
                <br></br>
                <Link
                  to="/auth/register-page"
                  style={{ color: roseColor[0], marginTop: "15px" }}
                >
                  {t("register-new-user")}
                </Link>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" round size="lg" block type="submit">
                  {t("log-in")}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
