/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import avatar from "assets/img/placeholder.jpg";
import Button from "components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import ModalComponent from "components/ModalComponent";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import ApiService from "api/ApiService.js";
import { Link } from "react-router-dom";
import moment from "moment";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { formatDate } from "api/utils";
import Select from "@material-ui/core/Select";
import i18n from "i18next";
import { lightBlue, grayColor } from "assets/jss/material-dashboard-pro-react";
import { dangerColor } from "assets/jss/material-dashboard-pro-react";
var ps;

const clearFileUploadState = {
  showFileUploadDialog: false,
  parentId: undefined,
  fileTitle: "",
  fileTitleInputActive: false,
  fileTTL: moment().add(ApiService.defaultStorageDays, "days"),
  storeForever: [],
  selectedFile: undefined,
  pricing: {},
  showProgress: false,
  progress: 0,
  progressRuntime: 0,
  message: "",
  successMessage: "",
  abortController: undefined,
  confirmDialog: false,
  confirmButtonText: i18n.t("upload"),
  jobId: "",
  superuser: "",
  userId: "",
  formattedUserName: "",
  selectedValue: "Sent invoice",
  amountExcludingVat: "",
  invoiceNumber: "",
  invoiceDate: "",
  currency: "",
  customerOrganizationNumber: "",
};

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAvatar: false,
      miniActive: true,
      userProfile: {},
      openSnackbar: false,
      userAvatar: avatar,
      lastname: "",
      lastnameState: "",
      fileTitle: "",
      selectedFile: null,
      loading: false,
      showSuccess: false,
      successMessage: "",
      errorMessage: "",
      language: props.language,
      ...this.getCollapseStates(props.routes),
      ...clearFileUploadState,
    };
    this.fileInput = React.createRef();

    ApiService.loginRequired(undefined, false)
      .then((userProfile) => {
        ApiService.getUserDocuments({ userId: userProfile._id })
          .then((responseData) => {
            const documentData = responseData.find(
              (entry) => entry.type === ApiService.documentTypes.avatar
            );
            if (documentData) {
              ApiService.readUserDocument({
                userId: userProfile._id,
                documentCriteria: ApiService.documentCriteria.documentType,
                documentFilter: ApiService.documentTypes.avatar,
              })
                .then((userAvatar) => {
                  this.setState({
                    userAvatar: `data:image/png;base64, ${userAvatar.contents}`,
                  });
                })
                .catch((e) => {
                  console.error(e);
                });
            }
          })
          .catch((e) => {
            console.error(e);
          });
        this.setState({ userProfile });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getQueryParams = async (signal = undefined) => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const params = {
      userId: queryParams.get("userId"),
    };
    const returnData = await ApiService.loginRequired(signal, false);
    if (returnData.superuser && params.userId) {
      const userProfileData = await ApiService.readUser(params, signal);
      this.setState({
        formattedUserName: ApiService.getFormattedUserName(
          returnData._id,
          userProfileData
        ),
      });
      params.userId = userProfileData._id;
    } else {
      params.userId = returnData._id;
    }
    params.superuser = returnData.superuser;
    params.parentId = "";
    return params;
  };

  handleShowFileUploadDialog = () => {
    this.setState(clearFileUploadState);

    if (ApiService.isUserValidated(this.state.userProfile)) {
      this.getQueryParams().then((params) => {
        this.setState({
          superuser: params.superuser,
          userId: params.userId,
          parentId: params.parentId,
          showFileUploadDialog: true,
        });
      });
    }
  };

  handleFileUpload = async (event) => {
    event.preventDefault();

    if (
      this.state.fileTitle.trim().length === 0 ||
      (this.state.selectedValue !== "Other" &&
        (this.state.amountExcludingVat.trim().length === 0 ||
          this.state.invoiceNumber.trim().length === 0 ||
          this.state.customerOrganizationNumber.trim().length === 0 ||
          this.state.invoiceDate.trim().length === 0 ||
          this.state.currency.trim().length === 0))
    ) {
      this.setState({
        errorMessage: i18n.t("please-fill-out-all-required-fields"),
      });
      return;
    }

    this.setState({ loading: true });
    const fileTitle = this.state.fileTitle.trim();
    const selectedFile = this.state.selectedFile;
    const fileTTL = "-1";

    const params = {
      file: selectedFile,
      shareable: "true",
      ttl: fileTTL.toString(),
      encoding: ApiService.storageFormat,
      title: fileTitle,
      message: "File upload",
      flags: JSON.stringify([{ name: "abendum", value: true }]),
    };

    if (this.state.superuser) {
      params.userId = this.state.userId;
    }
    if (this.props.containerId) {
      params.parentId = this.props.containerId;
    }

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    this.setState({ abortController });

    try {
      const keyParams = {};
      if (this.state.superuser) {
        keyParams.userId = this.state.userId;
      }
      const { address } = await ApiService.getPublicKey(keyParams, signal);
      params.receivers = JSON.stringify([address]);
    } catch (e) {
      this.setState({
        errorMessage: i18n.t("error-fetching-user-data"),
        loading: false,
      });
      return;
    }

    // Upload file to server
    let responseData;
    try {
      responseData = await ApiService.uploadFile(params, signal);
    } catch (e) {
      this.setState({
        errorMessage: e.message,
        openSnackbar: true,
        loading: false,
      });
      return;
    }
    const jobId = responseData.jobId;
    this.setState({
      jobId,
      progress: responseData.progress,
      progressRuntime: responseData.runtime,
    });
    try {
      const jobParams = { jobId };
      if (this.state.superuser) {
        jobParams.userId = this.state.userId;
      }
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
        this.setState({
          progress: responseData.progress,
          progressRuntime: responseData.runtime,
        });
        if (responseData.status === ApiService.jobStatus.failed) {
          this.setState({
            loading: false,
            errorMessage: i18n.t("error-uploading-file"),
          });
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          this.setState({
            loading: false,
            showSuccess: true,
            showFileUploadDialog: false,
          });

          const data = responseData.details.blockchainTransactions[0];
          const queryParameters = new URLSearchParams({
            data_id: data.data_id,
            hash: data.rawHash,
            sender: data.senderAddress,
            receiver: data.receiverAddress,
          });

          const packageDetails = await ApiService.getPackageDetails(
            queryParameters
          );
          const parentId = packageDetails._id;
          const parentType = "PACKAGE";

          await ApiService.addMetadata(
            {
              parentId,
              parentType,
              name: "Document Type",
              type: ApiService.metadataTypes.string,
              metadata: this.state.selectedValue,
            },
            signal
          );

          if (this.state.invoiceNumber) {
            await ApiService.addMetadata(
              {
                parentId,
                parentType,
                name: "Invoice number",
                type: ApiService.metadataTypes.string,
                metadata: this.state.invoiceNumber,
              },
              signal
            );
          }
          if (this.state.amountExcludingVat) {
            await ApiService.addMetadata(
              {
                parentId,
                parentType,
                name: "Amount Excluding VAT",
                type: ApiService.metadataTypes.string,
                metadata: this.state.amountExcludingVat,
              },
              signal
            );
          }
          if (this.state.customerOrganizationNumber) {
            await ApiService.addMetadata(
              {
                parentId,
                parentType,
                name: "Customer organization number",
                type: ApiService.metadataTypes.string,
                metadata: this.state.customerOrganizationNumber,
              },
              signal
            );
          }
          if (this.state.currency) {
            await ApiService.addMetadata(
              {
                parentId,
                parentType,
                name: "Currency",
                type: ApiService.metadataTypes.string,
                metadata: this.state.currency,
              },
              signal
            );
          }
          if (this.state.invoiceDate) {
            await ApiService.addMetadata(
              {
                parentId,
                parentType,
                name: "Invoice date",
                type: ApiService.metadataTypes.string,
                metadata: this.state.invoiceDate,
              },
              signal
            );
          }

          this.props.history.push(
            `/admin/documents/details?${queryParameters}`
          );
          return;
        }
      }
      if (signal.aborted) {
        this.setState({
          errorMessage: i18n.t("operation-aborted"),
          loading: false,
        });
        return;
      }
    } catch (e) {
      this.setState({
        errorMessage: i18n.t("error-uploading-file"),
        loading: false,
      });
      return;
    }
  };

  handleChange = (event) => {
    this.setState({ selectedValue: event.target.value });
  };

  handleCloseModal = () => {
    this.fileInput.current.value = "";
    this.setState(clearFileUploadState);
  };

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  mainPanel = React.createRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : i18n.t(prop.name)}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.path),
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : i18n.t(prop.name)}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  render() {
    const {
      classes,
      logo,
      miniLogo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
    } = this.props;

    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive,
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive,
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive,
      });
    var user = (
      <>
        <div className={userWrapperClass}>
          <div className={photo}>
            <img
              src={this.state.userAvatar}
              className={classes.avatarImg}
              alt="..."
            />
          </div>
          <List className={classes.list}>
            <ListItem className={classes.item + " " + classes.userItem}>
              <NavLink
                to="/admin/user-page"
                className={classes.itemLink + " " + classes.userCollapseButton}
                onClick={() => this.openCollapse("openAvatar")}
              >
                <ListItemText
                  primary={this.state.userProfile.name}
                  secondary={
                    <b
                      className={
                        caret +
                        " " +
                        classes.userCaret +
                        " " +
                        (this.state.openAvatar ? classes.caretActive : "")
                      }
                    />
                  }
                  disableTypography={true}
                  className={itemText + " " + classes.userItemText}
                />
              </NavLink>
              <Collapse in={this.state.openAvatar} unmountOnExit>
                <List className={classes.list + " " + classes.collapseList}>
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/admin/user-page"
                      className={
                        classes.itemLink + " " + classes.userCollapseLinks
                      }
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? "و" : "P"}
                      </span>
                      <ListItemText
                        primary={rtlActive ? "إعدادات" : "Profile"}
                        disableTypography={true}
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/auth/login-page"
                      className={
                        classes.itemLink + " " + classes.userCollapseLinks
                      }
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? "و" : "L"}
                      </span>
                      <ListItemText
                        primary={rtlActive ? "إعدادات" : i18n.t("logout")}
                        disableTypography={true}
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                </List>
              </Collapse>
            </ListItem>
          </List>
        </div>
        <div className={userWrapperClass}>
          <List className={classes.list}>
            <ListItem className={classes.item} style={{ textAlign: "center" }}>
              {(() => {
                if (this.props.miniActive && this.state.miniActive) {
                  return (
                    <Button
                      color="primary"
                      block
                      type="submit"
                      style={{ borderRadius: "25px", maxWidth: "70%" }}
                      onClick={this.handleShowFileUploadDialog}
                    >
                      <AddIcon />
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      color="primary"
                      block
                      type="submit"
                      style={{ borderRadius: "25px", maxWidth: "80%" }}
                      onClick={this.handleShowFileUploadDialog}
                    >
                      <AddIcon style={{ marginRight: "10px" }} />
                      {i18n.t("new-document")}
                    </Button>
                  );
                }
              })()}
            </ListItem>
          </List>
        </div>
        <ModalComponent
          open={this.state.showFileUploadDialog}
          handleClose={this.handleCloseModal}
          dialogTitle={i18n.t("upload-document")}
          maxWidth="xs"
          dialogContent={
            <>
              <Grid container justifyContent="center" alignItems="baseline">
                <Grid item xs={12} sm={12}>
                  <h4
                    style={{
                      fontWeight: "300",
                      margin: "10px 0 30px",
                      color: grayColor[3],
                    }}
                  >
                    {i18n.t("select-a-document-and-upload-it")}
                  </h4>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div
                    className="picture-container"
                    style={{ border: "1px dashed grey", borderRadius: "10px" }}
                  >
                    <div className="picture1">
                      <CloudUploadIcon
                        style={{
                          width: "2.5em",
                          height: "2.5em",
                          color: lightBlue[0],
                          paddingTop: "1em",
                        }}
                      />
                      <label
                        htmlFor="files"
                        className="btn"
                        style={{ display: "block" }}
                      >
                        {i18n.t("select-file")}
                      </label>
                      <input
                        type="file"
                        id="files"
                        accept="application/pdf"
                        ref={this.fileInput}
                        disabled={this.state.loading}
                        onChange={(e) => {
                          this.setState({
                            loading: false,
                            showSuccess: false,
                            openSnackbar: false,
                            errorMessage: "",
                            successMessage: "",
                          });
                          const selectedFile = e.target.files[0];
                          if (selectedFile) {
                            this.setState({
                              fileTitle: selectedFile.name,
                              selectedFile,
                              lastnameState: "success",
                            });
                          }
                        }}
                      />
                    </div>

                    <h6 className="description">
                      {this.state.selectedFile && this.state.selectedFile.name}
                    </h6>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <CustomInput
                    success={this.state.lastnameState === "success"}
                    error={this.state.lastnameState === "error"}
                    labelText={<span>{i18n.t("title")} *</span>}
                    id="title"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      value: this.state.fileTitle,
                      disabled: this.state.loading,
                      onChange: (e) => {
                        this.change(e, "lastname", "length", 3);
                        this.setState({
                          fileTitle: e.target.value,
                          errorMessage: "",
                        });
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ position: "relative" }}
                        >
                          <span></span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormLabel
                    className={
                      classes.labelHorizontal +
                      " " +
                      classes.labelHorizontalRadioCheckbox
                    }
                  >
                    {i18n.t("type")}
                  </FormLabel>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={this.state.selectedValue === "Sent invoice"}
                          onChange={this.handleChange}
                          value="Sent invoice"
                          name="Sent invoice"
                          aria-label="Sent invoice"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={i18n.t("sent-invoice")}
                    />
                  </div>

                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={this.state.selectedValue === "Other"}
                          onChange={this.handleChange}
                          value="Other"
                          name="Other"
                          aria-label="Other"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={i18n.t("other")}
                    />
                  </div>
                </Grid>
              </Grid>
              {this.state.selectedValue === "Sent invoice" && (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth style={{ paddingTop: "24px" }}>
                      <Datetime
                        timeFormat={false}
                        inputProps={{
                          placeholder: `${i18n.t("invoice-date")} *`,
                        }}
                        value={
                          this.state.invoiceDate !== null &&
                          this.state.invoiceDate.length > 0
                            ? formatDate(this.state.invoiceDate)
                            : null
                        }
                        onChange={(e) =>
                          this.setState({
                            invoiceDate: moment(e).toISOString(),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      labelText={`${i18n.t("invoice-number")}`}
                      id={"invoice-number"}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          this.setState({
                            invoiceNumber: e.target.value,
                            errorMessage: "",
                          });
                        },
                        value: this.state.invoiceNumber,
                        type: "text",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CustomInput
                      labelText={`${i18n.t("customer-organization-number")} *`}
                      id={"customer-organization-number"}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          this.setState({
                            customerOrganizationNumber: e.target.value,
                            errorMessage: "",
                          });
                        },
                        value: this.state.customerOrganizationNumber,
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      labelText={`${i18n.t("amount-excluding-vat")} *`}
                      id={"amount-excluding-vat"}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          this.setState({
                            amountExcludingVat: e.target.value,
                            errorMessage: "",
                          });
                        },
                        value: this.state.amountExcludingVat,
                        type: "number",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        {i18n.t("currency")} *
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={this.state.currency}
                        onChange={(e) => {
                          this.setState({ currency: e.target.value });
                        }}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          {i18n.t("currency")}
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="AUD"
                        >
                          AUD
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="CZK"
                        >
                          CZK
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="DKK"
                        >
                          DKK
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="HUF"
                        >
                          HUF
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="JPY"
                        >
                          JPY
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="NOK"
                        >
                          NOK
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="SEK"
                        >
                          SEK
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="CHF"
                        >
                          CHF
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="GBP"
                        >
                          GBP
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="USD"
                        >
                          USD
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="BAM"
                        >
                          BAM
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="EUR"
                        >
                          EUR
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="PLN"
                        >
                          PLN
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {this.state.loading && (
                <LinearProgress color="primary" style={{ marginTop: "10px" }} />
              )}
              {this.state.errorMessage && (
                <h6
                  className={classes.description}
                  style={{ color: dangerColor[0] }}
                >
                  {this.state.errorMessage}
                </h6>
              )}
            </>
          }
          dialogActions={
            <React.Fragment>
              <Button
                simple
                disabled={this.state.loading}
                onClick={this.handleCloseModal}
                color="rose"
              >
                {i18n.t("cancel")}
              </Button>
              <Button
                color="primary"
                onClick={this.handleFileUpload}
                disabled={
                  this.state.selectedFile === null || this.state.loading
                }
              >
                <CloudUploadIcon className={classes.icons} /> {i18n.t("upload")}
              </Button>
            </React.Fragment>
          }
        />
      </>
    );

    var links = (
      <List className={classes.list}>{this.createLinks(routes)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const miniLogoMini = classes.miniLogoMini;
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });

    var brand = (
      <div className={logoClasses}>
        <Link to="/admin/dashboard">
          <div
            className={
              this.props.miniActive && this.state.miniActive
                ? miniLogoMini
                : logoMini
            }
          >
            <img
              src={
                this.props.miniActive && this.state.miniActive ? miniLogo : logo
              }
              alt="logo"
              className={
                this.props.miniActive && this.state.miniActive
                  ? classes.miniImg
                  : classes.img
              }
            />
          </div>
        </Link>

        <Link to="/admin/dashboard">
          <div className={logoNormal}>{logoText}</div>
        </Link>
      </div>
    );

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={
                <AdminNavbarLinks
                  rtlActive={rtlActive}
                  history={this.props.history}
                />
              }
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  history: PropTypes.object.isRequired,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default withStyles(sidebarStyle)(Sidebar);
