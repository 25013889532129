import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { grayColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles((theme) => ({
  details: {
    alignItems: "center",
    display: "block",
    // borderTop: '1px solid #f5f5f5',
  },
  expandIcon: {
    "& .MuiIconButton-root": {
      "@media (min-width: 960px)": {
        display: "none",
      },
    },
  },
  hideIcon: {
    color: grayColor[19],
  },
  disabled: {
    backgroundColor: `${grayColor[19]} !important`,
    "&::before": {
      backgroundColor: grayColor[19],
    },
  },
}));

const ExpansionPanelComponent = ({
  panelSummary,
  panelDetails,
  index,
  handleAction = null,
  elevation = 0,
  hideIcon = false,
  disabled = false,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpand = (event, expand) => {
    setExpanded(expand);
  };

  const getSummaryClass = () => {
    const classObj = {};
    if (hideIcon) {
      classObj.root = classes.expandIcon;
    }
    if (disabled) {
      classObj.expandIcon = classes.hideIcon;
    }
    return classObj;
  };

  return (
    <Accordion
      elevation={elevation}
      onChange={(event, expanded) => handleExpand(event, expanded)}
      expanded={expanded}
      disabled={disabled}
      classes={{ disabled: classes.disabled }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id={index}
        classes={getSummaryClass()}
      >
        {panelSummary}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {expanded && panelDetails}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpansionPanelComponent;
