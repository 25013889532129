import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button";
import InfoIcon from "@material-ui/icons/Info";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

export default function DocumentsTable(props) {
  let documents = props.documents || [];

  const classes = useStyles();

  return (
    <>
      <Table
        tableHead={["Title", "File name", "Format", "Size", "Details"]}
        tableData={documents
          .filter((doc) => doc.status !== "DELETED")
          .map((docs) => {
            return [
              docs.title,
              docs.fileName,
              docs.dataType,
              docs.fileSize,
              <Button
                color="info"
                simple
                className={classes.actionButton}
                key={docs.title}
                onClick={(e) => props.handleShowDetails(e, docs)}
              >
                <InfoIcon className={classes.icon} />
              </Button>,
            ];
          })}
        customCellClasses={[
          classes.left,
          classes.right,
          classes.right,
          classes.right,
          classes.right,
        ]}
        customClassesForCells={[0, 3, 4, 5, 6]}
        customHeadCellClasses={[
          classes.left,
          classes.right,
          classes.right,
          classes.right,
          classes.right,
        ]}
        customHeadClassesForCells={[0, 3, 4, 5, 6]}
      />
    </>
  );
}
