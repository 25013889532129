import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DocumentUpload = ({
  title,
  titleTooltip,
  updatedAt,
  image,
  fileName,
  type,
  onUpload,
  onDownload,
  loading,
  editable,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const fileInput = React.createRef();

  const handleClick = () => {
    if (!loading && editable === true) fileInput.current.click();
  };

  const handleUpload = (e) => {
    e.preventDefault();
    onUpload(e.target.files[0]);
  };

  const handleDownload = () => {
    onDownload();
  };

  const filePreview = () => {
    switch (type) {
      case "application/pdf":
        return (
          <div className={classes.fileIconContainer}>
            <DescriptionIcon />
            {fileName.length > 20 ? `${fileName.slice(0, 20)} ...` : fileName}
          </div>
        );
      case "image/png":
      case "image/jpeg":
        return <img src={image} alt="file" className={classes.image} />;
      default:
        break;
    }
  };

  return (
    <div className={classes.container}>
      <div>
        <Badge
          invisible={!image || editable === false}
          //color="secondary"
          badgeContent={
            <IconButton
              className={classes.iconBg}
              aria-label="edit"
              onClick={() => handleClick()}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          }
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ marginRight: 30 }}
        >
          <div className={classes.imageContainer} onClick={() => handleClick()}>
            {image && filePreview()}
            <div className="fileinput">
              <input type="file" ref={fileInput} onChange={handleUpload} />
              {!image && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {loading && (
                    <>
                      <CircularProgress size={20} style={{ marginRight: 10 }} />
                      <h5>{t("uploading")} ...</h5>
                    </>
                  )}
                  {!loading && !image && editable === true && (
                    <>
                      <AddIcon style={{ marginRight: 10 }} />
                      <h5>{t("add")}</h5>
                    </>
                  )}
                  {!loading && !image && editable === false && (
                    <h5>{t("no-document")}</h5>
                  )}
                </div>
              )}
            </div>
          </div>
        </Badge>
      </div>
      <div className={classes.box}>
        <div className={classes.titleBox}>
          <div style={{ fontSize: 18 }}>{title}</div>
          <div>
            {titleTooltip && (
              <Tooltip title={titleTooltip}>
                <InfoOutlinedIcon color="disabled" />
              </Tooltip>
            )}
          </div>
        </div>
        {updatedAt && moment(updatedAt).format("DD.MM.YYYY hh:mm")}
        {image && (
          <>
            <br />
            <Button size="small" variant="outlined" onClick={handleDownload}>
              {t("download")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 30,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    margin: "auto",
    width: 150,
    height: 80,
    border: "1px solid #eeeeee",
  },
  image: {
    height: 60,
  },
  iconBg: {
    backgroundColor: "#eeeeee",
  },
  fileIconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    width: 150,
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default DocumentUpload;
