import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ApiService from "api/ApiService.js";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import isEmail from "validator/lib/isEmail";
import { useTranslation } from "react-i18next";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

export default function RegisterPage(props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [companyPosition, setCompanyPosition] = React.useState("");
  const [availableOrganizations, setAvailableOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [organizationError, setOrganizationError] = React.useState("");

  const handleCloseMessage = () => {
    setMessage("");
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const showErrorMessage = (messageContent) => {
    setMessage(messageContent);
  };

  const checkOrganization = (selectedOrganization) => {
    const company = availableOrganizations.find(
      (org) => org.toLowerCase() === selectedOrganization.toLowerCase()
    );
    if (company) {
      return company;
    }
    return false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validName = customerName.length > 0;
    const validEmail = isEmail(email);
    const validOrganization = checkOrganization(selectedOrganization);
    const validPasswordLength = password.length > 6;
    const passwordsMatch = password === confirmPassword;
    const termsConditionsChecked = checked.length > 0;
    const checksPassed =
      validName &&
      validEmail &&
      validOrganization &&
      validPasswordLength &&
      passwordsMatch &&
      termsConditionsChecked;

    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        setNameError(t("name-min-length"));
        messageContent.push(t("name-min-length"));
      }
      if (!validEmail) {
        setEmailError(t("invalid-email"));
        messageContent.push(t("invalid-email"));
      }
      if (!validOrganization) {
        setOrganizationError(t("invalid-company-name"));
        messageContent.push(t("invalid-company-name"));
      }
      if (!validPasswordLength) {
        setPasswordError(t("password-min-length"));
        messageContent.push(t("password-min-length"));
      }
      if (!passwordsMatch) {
        setConfirmPasswordError(t("passwords-not-match"));
        messageContent.push(t("passwords-not-match"));
      }
      if (!termsConditionsChecked) {
        setMessage(t("agree-terms-conditions"));
      }

      return;
    }

    try {
      await ApiService.createUser({
        name: customerName,
        email,
        password,
        companyName: validOrganization,
        companyPosition,
        services: ApiService.defaultRegistrationServices,
      });
      props.history.push("/");
    } catch (e) {
      if (e.message.includes("email")) {
        showErrorMessage(t("email-already-exists"));
      } else if (e.message.includes("name")) {
        showErrorMessage(t("name-already-exists"));
      } else {
        showErrorMessage(e.message);
      }
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;
    const apiOperations = async () => {
      try {
        let organizations = await ApiService.getAvailableOrganizations(signal);
        organizations = organizations.map((organization) => organization.name);
        organizations.sort();
        setAvailableOrganizations(organizations);
      } catch (e) {
        console.error(e);
        showErrorMessage(e.message);
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>{t("register")}</h2>
            <CardBody>
              {message.length > 0 && (
                <SnackbarContent
                  message={message}
                  close
                  color="danger"
                  click={handleCloseMessage}
                />
              )}

              <form className={classes.form} onSubmit={handleSubmit}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    type: "text",
                    placeholder: t("name"),
                    required: true,
                    onChange: (e) => {
                      setNameError("");
                      setCustomerName(e.target.value);
                    },
                    value: customerName,
                  }}
                  error={nameError.length > 0}
                  helperText={nameError}
                />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    type: "text",
                    placeholder: t("company-name"),
                    required: true,
                    onChange: (e) => {
                      setOrganizationError("");
                      setSelectedOrganization(e.target.value);
                    },
                    value: selectedOrganization,
                  }}
                  error={organizationError.length > 0}
                  helperText={organizationError}
                />

                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    placeholder: t("job-title"),
                    required: false,
                    onChange: (e) => {
                      setCompanyPosition(e.target.value);
                    },
                  }}
                />

                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    placeholder: t("email"),
                    required: true,
                    autoComplete: "off",
                    onChange: (e) => {
                      setEmailError("");
                      setEmail(e.target.value);
                    },
                  }}
                  error={emailError.length > 0}
                  helperText={emailError}
                />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    required: true,
                    placeholder: t("password"),
                    type: "password",
                    autoComplete: "off",
                    onChange: (e) => {
                      setPasswordError("");
                      setPassword(e.target.value);
                    },
                  }}
                  error={passwordError.length > 0}
                  helperText={passwordError}
                />
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    required: true,
                    type: "password",
                    placeholder: t("password-again"),
                    autoComplete: "off",
                    onChange: (e) => {
                      setConfirmPasswordError("");
                      setConfirmPassword(e.target.value);
                    },
                  }}
                  error={confirmPasswordError.length > 0}
                  helperText={confirmPasswordError}
                />
                <FormControlLabel
                  classes={{
                    root: classes.checkboxLabelControl,
                    label: classes.checkboxLabel,
                  }}
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(1)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  label={
                    <span>
                      {t("i-agree")}{" "}
                      <a
                        href="https://abendum.com/terms-and-conditions/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: primaryColor[0] }}
                      >
                        {t("terms-conditions")}
                      </a>
                      .
                    </span>
                  }
                />
                <div className={classes.center}>
                  <Button round color="primary" onClick={handleSubmit}>
                    {t("get-started")}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
