import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";

// @material-ui/icons
import MenuIcon from "@material-ui/icons/Menu";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LanguageIcon from "@material-ui/icons/Language";
import ApiService from "../../api/ApiService";
import CompanyTheme from "utils/CompanyTheme";

// core components
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const { i18n } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const currentLanguage = i18n.language;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectLangueage = (lang) => {
    changeLanguage(lang);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  var list = (
    <>
      <List className={classes.list}>
        <ListItem
          className={classes.listItem}
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          <div className={cx(classes.navLink)}>
            <LanguageIcon className={classes.listItemIcon} />
            {currentLanguage}
          </div>
        </ListItem>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleSelectLangueage("en");
              handleClose();
            }}
          >
            EN
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleSelectLangueage("es");
              handleClose();
            }}
          >
            ES
          </MenuItem>
        </Menu>
        {ApiService.showRegisterPage && (
          <ListItem className={classes.listItem}>
            <NavLink
              to={"/auth/register-page"}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute("/auth/register-page"),
              })}
            >
              <PersonAdd className={classes.listItemIcon} />
              <ListItemText
                primary={"Register"}
                disableTypography={true}
                className={classes.listItemText}
              />
            </NavLink>
          </ListItem>
        )}
        <ListItem className={classes.listItem}>
          <NavLink
            to={"/auth/login-page"}
            className={cx(classes.navLink, {
              [classes.navLinkActive]: activeRoute("/auth/login-page"),
            })}
          >
            <Fingerprint className={classes.listItemIcon} />
            <ListItemText
              primary={"Login"}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      </List>
    </>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <img
              src={CompanyTheme.getLogo()}
              alt={ApiService.logoText}
              className={classes.logoImage}
            />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <img
              src={CompanyTheme.getLogo()}
              alt={ApiService.logoText}
              className={classes.logoImage}
            />
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
