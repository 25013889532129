/*eslint-disable*/
import React from "react";
import ApiService from "api/ApiService.js";
import SnackBarComponent from "components/SnackBarComponent.js";

export default function PictureUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [variant, setVariant] = React.useState("success");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleImageChange = async (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    reader.onloadend = () => {
      setFile(newFile);
      //setImagePreviewUrl(reader.result);
    };
    if (newFile) {
      reader.readAsDataURL(newFile);

      try {
        const { message } = await ApiService.upsertUserImage({
          userId: props.userId,
          documentType: props.documentType,
          encryptDocument: true,
          resizeImage: true,
          imageWidth: 400,
          imageHeight: 400,
          file: newFile,
        });
        const responseData = await ApiService.readUserDocument({
          userId: props.userId,
          documentCriteria: ApiService.documentCriteria.documentType,
          documentFilter: props.documentType,
        });
        //setUploadedDocument(responseData.document);
        setImagePreviewUrl(`data:image/png;base64, ${responseData.contents}`);
      } catch (e) {
        console.error(e);
        setSnackbarMessage(e.message);
        setVariant("error");
        setOpenSnackbar(true);
      }
    }
  };
  return (
    <>
      <div className="picture-container">
        <div className="picture">
          <img
            src={imagePreviewUrl.length > 0 ? imagePreviewUrl : props.image}
            className="picture-src"
            alt="..."
          />
          <input type="file" onChange={(e) => handleImageChange(e)} />
        </div>
      </div>
      <SnackBarComponent
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        variant={variant}
        message={snackbarMessage}
      />
    </>
  );
}
