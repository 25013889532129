import moment from "moment";

export const formatDate = (date) => moment(date).format("DD.MM.YYYY");
export const formatDateTime = (dateTime, endLine = false) => {
  if (endLine) {
    return moment(dateTime).format("DD.MM.YYYY [\n]HH:mm");
  } else {
    return moment(dateTime).format("DD.MM.YYYY HH:mm");
  }
};
